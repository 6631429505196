/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './support.module.scss';
import axios from 'axios';
import { API_SUPPORT_LIST } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AdminSupportComponent from './admin-response-modal';
import ViewSupportComponent from './response-view-modal';

const SupportComponent = ({ token }) => {
    const [supportList, setsupportList] = useState([]);
    const [supportListBackUp, setsupportListBackUp] = useState([]);
    // const [pageLimit, setpageLimit] = useState(5);
    const pageLimit = useRef(5);
    // const [pageNo, setpageNo] = useState(0);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(10000000);
    const [cacheNextUserList, setcacheNextUserList] = useState([]);
    const [cachePrevUserList, setcachePrevUserList] = useState([]);

    const getNextPrevSupportList = async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_SUPPORT_LIST, { page: nextPageNo, size: pageLimit.current }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.ticketList : []);
                } else {
                    setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.ticketList : []);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const getAllSupportList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_SUPPORT_LIST, { page: pageNo.current, size: pageLimit.current }, { headers });
            if (res.data.responseCode === 0) {
                setsupportList(res.data.response.ticketList);
                setsupportListBackUp(res.data.response.ticketList);
                if (res.data.response.ticketList.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevSupportList();
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const nextCacheSet = () => {
        if (cacheNextUserList.length > 0) {
            setcachePrevUserList(supportList)

            setsupportList(cacheNextUserList);
            setsupportListBackUp(cacheNextUserList);

            setcacheNextUserList([]);
            getNextPrevSupportList('next');
        } else {
            setcacheNextUserList([]);
            getAllSupportList();
        }
    }

    const prevCacheSet = () => {
        if (cachePrevUserList.length > 0) {
            setcacheNextUserList(supportList);
            //   this.cacheNextUserList = this.apiAllUsers;
            setsupportList(cachePrevUserList);
            setsupportListBackUp(cachePrevUserList);
            setcachePrevUserList([]);
            getNextPrevSupportList('prev');
        } else {
            setcachePrevUserList([]);
            getAllSupportList();
        }
    }


    let i = 0;
    useEffect(() => {
        if (i === 0 && cacheNextUserList.length === 0) {
            getAllSupportList();
            i++;
        }
    }, [cacheNextUserList]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextUserList([]);
        setcachePrevUserList([]);
        i = 0;
    }




    const nextPrev = (val) => {

        const page = pageNo.current + 1;

        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }

    const entriesPerPage = (e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
    }

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };


    const [modalShowView, setModalShowView] = useState(false);
    const seletedObjView = useRef('');

    const handleResponceOpenView = (eleObj) => {
        seletedObjView.current = eleObj;
        setModalShowView(true);
    }

    const handleResponcecloseView = () => {
        setModalShowView(false);
        seletedObjView.current = '';
    }

    const [modalShow, setModalShow] = useState(false);
    const seletedObj = useRef('');

    const handleResponceOpen = (eleObj) => {
        seletedObj.current = eleObj;
        setModalShow(true);
    }

    const handleResponceAccept = () => {
        seletedObj.current = '';
        setModalShow(false);
        resetCache();
    }

    const handleResponceclose = () => {
        setModalShow(false);
        seletedObj.current = '';
    }

    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <h5 className='customTitleName'>Support</h5>
                    <div className={`card ${styles.customCard}`}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-100 customPginationDiv">
                                        <h6 className="customText">Items per page</h6>
                                        <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                        <button className="btn customButtons" disabled={pageLimit.current >= supportList.length && cacheNextUserList.length === 0} onClick={() => nextPrev('next')}>
                                            <i className="fas fa-angle-right"></i></button>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">Agent</th>
                                                    {/* <th className="headText">User Type</th> */}
                                                    <th className="headText">Contact Number</th>
                                                    <th className="headText">Agent Issues</th>
                                                    <th className="headText">Admin Responce</th>
                                                    <th className="headText text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                                {supportList && supportList.length > 0 && supportList.map((ele) => (<tr>
                                                    <td>
                                                        <div className="d-flex w-100 align-items-center">
                                                            <div>
                                                                <img src={ele.agentImage} onError={handleImageErr} alt="" width="38" height="38"
                                                                    style={{ borderRadius: '50%' }} />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h6 className="m-0 bodyText">{ele.fullName}</h6>
                                                                {/* <h6 className="m-0 locationText">Bangalore</h6> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td className="bodyText">{ele.userType}</td> */}
                                                    <td className="bodyText">{ele.mobileNumber}</td>
                                                    <td className="bodyText">
                                                        <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    {ele.agentIssue}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <p className="bodyTextEllipsis">{ele.agentIssue}</p>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td className="bodyText">
                                                        {ele.adminResponse ? <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    {ele.adminResponse}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <p className="bodyTextEllipsis">{ele.adminResponse}</p>
                                                        </OverlayTrigger> : '-'}
                                                    </td>
                                                    <td className="bodyText text-center">
                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to view
                                                                </Tooltip>
                                                            }
                                                        >

                                                            <i class={`far fa-eye ${styles.actionBtn}`} onClick={() => handleResponceOpenView(ele)}></i>

                                                        </OverlayTrigger>
                                                        {ele.status === 'pending' && <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to reply
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fas fa-reply" onClick={() => handleResponceOpen(ele)} style={{ opacity: '0.5', cursor: 'pointer' }}></i>
                                                        </OverlayTrigger>}

                                                        {ele.status === 'closed' ? ele.status : ''}
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AdminSupportComponent
                show={modalShow}
                rowData={seletedObj.current}
                onHide={handleResponceclose}
                onAccept={handleResponceAccept}
            />

            <ViewSupportComponent
                show={modalShowView}
                rowData={seletedObjView.current}
                onHide={handleResponcecloseView}
            />
        </>
    );
};

SupportComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(SupportComponent);