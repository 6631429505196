import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const MainLayout = ({isLoggedIn}) => {
  if (isLoggedIn) {
    return (<Navigate to="/app/dashboard" />);
  }


  return (
    <div className="container-fluid">
      <Outlet />
    </div>
  );
};

MainLayout.propTypes = {
  isLoggedIn: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn
})

export default connect(mapStateToProps, null)(MainLayout);;
