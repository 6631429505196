import React, { useState, useEffect, useRef } from "react";
import styles from "./dashboard.module.scss";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { API_GET_DASHBOARD } from "../../utils/consts/api";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineArrowUp } from "react-icons/ai";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonLoader from "../../components/Loader";

const DashboardComponent = ({ token }) => {
  const [showDashboard, setShowDashboard] = useState("");
  const [loader,setLoader]=useState(false);
  // const showDashboard = useRef('');

  const getDashboardContent = async () => {
    

    setLoader(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const res = await axios.post(API_GET_DASHBOARD, {}, { headers });
      if (res.data.responseCode === 0) {
        setShowDashboard(res.data.response)

        setLoader(false)
       
        
      } else if (res.data.responseCode === -1) {

        setLoader(false);

        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowDashboard("");
      }
    } catch (err) {

      setLoader(false);
      
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  let i = 0;
  useEffect(() => {
    if (i === 0) {
      getDashboardContent();
      i++;
    }
  }, []);

  
  return (
    <div className="row">
      <div className="col-12">
        <h5 className="customTitleName">Website Performance DashBoard</h5>
       

       
       {/* <Row>
        <Col style={{height:"150px",backgroundColor:"gray"}}></Col>
        <Col style={{height:"150px",backgroundColor:"red"}}>1 of 3</Col>
        <Col style={{height:"150px",backgroundColor:"yellow"}}>2 of 3</Col>
        <Col style={{height:"150px",backgroundColor:"green"}}>3 of 3</Col>
       </Row> */}
      {
        loader ?
        <Row style={{rowGap:"20px"}}>
        <Col xs={12} md={12} lg={12} xl={12} style={{ height: "150px",  }}>
          <Skeleton style={{ height: "100%" }} />
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} style={{ height: "150px", }}>
          <Skeleton style={{ height: "100%" }} />
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} style={{ height: "150px"}}>
          <Skeleton style={{ height: "100%" }} />
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} style={{ height: "150px",}}>
          <Skeleton style={{ height: "100%" }} />
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} style={{ height: "150px", }}>
          <Skeleton style={{ height: "100%" }} />
        </Col>
      </Row>
       :
       <Row>
       <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-3" >
       <Card className={`card-stats ${styles.headCard}`}>
         <Card.Header className={styles.headerCard}>
           <Card.Title as="h6">Overview For Today's Booking Details</Card.Title>
         </Card.Header>
         <Card.Body>
           <div>
             <Card className={styles.indiCard}>
               <Card.Body>
                 <div className={styles.singleCard}>
                   <div>
                     <Card.Title as="h5" style={{ color: "#f7b217" }} >
                       {showDashboard && showDashboard.todayBoookingDetails.newBookings}
                     </Card.Title>
                     <small>Today New Bookings</small>
                    </div>
                    <div>
                     <Card.Title as="h5" style={{ color: "#20C997" }}>
                       {showDashboard && showDashboard.todayBoookingDetails.assignedBookings}
                     </Card.Title>
                     <small>Today Assigned Bookings</small>
                   </div>
                   <div>
                     <Card.Title as="h5" style={{ color: "#20C997" }}>
                       {showDashboard && showDashboard.todayBoookingDetails.cancelBookings}
                     </Card.Title>
                     <small>Today Cancel Bookings</small>
                   </div>
                   <div>
                     <Card.Title as="h5" style={{ color: "#FF4D4F" }}>
                       {showDashboard && showDashboard.todayBoookingDetails.deletedBookings}
                     </Card.Title>
                     <small>Today Deleted Bookings</small>
                   </div>
                   <div>
                     <Card.Title as="h5" style={{ color: "#FF4D4F" }}>
                       {showDashboard && showDashboard.todayBoookingDetails.endBookings}
                     </Card.Title>
                     <small>Today End Bookings</small>
                   </div>
                   <div>
                     <Card.Title as="h5" style={{ color: "#f7b217" }} >
                       {showDashboard && showDashboard.todayBoookingDetails.expiredBookings}
                     </Card.Title>
                     <small>Today Expired Bookings</small>
                   </div>
                   <div>
                     <Card.Title as="h5" style={{ color: "#f7b217" }} >
                       {showDashboard && showDashboard.todayBoookingDetails.pickedBookings}
                     </Card.Title>
                     <small>Today Picked Bookings</small>
                   </div>
                 </div>
               </Card.Body>
             </Card>
           </div>
         </Card.Body>
       </Card>
     </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-3">
          <Card className={`card-stats ${styles.headCard}`}>
            <Card.Header className={styles.headerCard}>
              <Card.Title as="h6">Daily Overview For Agents</Card.Title>
            </Card.Header>
            <Card.Body>
              <div>
                <Card className={styles.indiCard}>
                  <Card.Body>
                    <div className={styles.singleCard}>
                      <div>
                        <Card.Title as="h5" style={{ color: "#f7b217" }}>
                          {showDashboard && showDashboard.agent.today}
                        </Card.Title>
                        <small> New Agent Today</small>
                      </div>
                      <div>
                        <Card.Title as="h5">
                          {showDashboard && showDashboard.agent.total}
                        </Card.Title>
                        <small>Total Agents</small>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-3">
          <Card className={`card-stats ${styles.headCard}`}>
            <Card.Header className={styles.headerCard}>
              <Card.Title as="h6">Daily Overview For Booking</Card.Title>
            </Card.Header>
            <Card.Body>
              <div>
                <Card className={styles.indiCard}>
                  <Card.Body>
                    <div className={styles.singleCard}>
                      <div>
                        <Card.Title as="h5" style={{ color: "#f7b217" }}>
                          {showDashboard && showDashboard.booking.today}
                        </Card.Title>
                        <small>New Booking Today</small>
                      </div>
                      <div>
                        <Card.Title as="h5">
                          {showDashboard && showDashboard.booking.total}
                        </Card.Title>
                        <small>Total Bookings</small>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-3">
          <Card className={`card-stats ${styles.headCard}`}>
            <Card.Header className={styles.headerCard}>
              <Card.Title as="h6">Daily Overview For Driver</Card.Title>
            </Card.Header>
            <Card.Body>
              <div>
                <Card className={styles.indiCard}>
                  <Card.Body>
                    <div className={styles.singleCard}>
                      <div>
                        <Card.Title as="h5" style={{ color: "#f7b217" }}>
                          {showDashboard && showDashboard.driver.today}
                        </Card.Title>
                        <small>Today Drivers</small>
                      </div>
                      <div>
                        <Card.Title as="h5">
                          {showDashboard && showDashboard.driver.total}
                        </Card.Title>
                        <small>Total Drivers</small>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-3">
          <Card className={`card-stats ${styles.headCard}`}>
            <Card.Header className={styles.headerCard}>
              <Card.Title as="h6">Daily Overview For Vehicle</Card.Title>
            </Card.Header>
            <Card.Body>
              <div>
                <Card className={styles.indiCard}>
                  <Card.Body>
                    <div className={styles.singleCard}>
                      <div>
                        <Card.Title as="h5" style={{ color: "#f7b217" }}>
                          {showDashboard && showDashboard.vehicle.today}
                        </Card.Title>
                        <small>Today Vehicles</small>
                      </div>
                      <div>
                        <Card.Title as="h5">
                          {showDashboard && showDashboard.vehicle.total}
                        </Card.Title>
                        <small>Total Vehicles</small>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
      }
       
      </div>
      {/* <div className="col-12" style={{ marginTop: '2%' }}>
                <img src={webImg} alt="" className={styles.dashboardImg} />
            </div> */}
    </div>
  );
};
DashboardComponent.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  token: state.user.token,
});

export default connect(mapStateToProps, null)(DashboardComponent);
