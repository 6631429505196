import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoader = ({height}) => {
    return (
        <div >
            <Skeleton style={{height:"50px"}}/>
            <Skeleton
            style={{height:height||"60vh"}}
             count={1}/>   
          </div>
    );
}

export default SkeletonLoader;
