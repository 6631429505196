import React from 'react';
import { BiPhone } from 'react-icons/bi';
import "./bookingListPage.scss";
import "../../../index.scss";
const BookingDriver = (props) => {
    return (
        <div className="bodyText" >
            <h6 >{props.data.actualData.driverAgentName ? props.data.actualData.driverAgentName : 'NA'}</h6>
            <h6 style={{ fontSize: '11.2px' }}><BiPhone /> {props.data.actualData.driverMobileNo ? '+91 ' + props.data.actualData.driverMobileNo : 'NA'}</h6>
        </div>
    );
}

export default BookingDriver;
