import React, { useState, useEffect, useRef } from "react";
import { Navigate, useParams, Link } from 'react-router-dom';
import style from './users.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_USER_DRIVER_DETAILS, API_USER_VEHICLE_DETAILS, API_DELETE_DRIVER, API_DELETE_VEHICLE } from '../../utils/consts/api';
import axios from 'axios';
import { BsArrowLeft } from 'react-icons/bs';
import flagImg from '../../assets/images/ProfileImage.png';
import coverImg from '../../assets/images/Cover imagenew.png';
import { BsTelephone } from "react-icons/bs";
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddEditDriverComponent from './add-edit-driver-modal';
import AddEditVehicleComponent from './add-edit-vehicle-modal';
import DeleteDriverAndVehicleComponent from './delete-driver-vehicle-modal';
// import EditWalletComponent from './editWallet';

function UserModuleComponent({ token, userDetal, userDetailImage }) {
    const [driverList, setdriverList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const userObj = useRef(userDetal);
    const { userId } = useParams();


    const getAllDriverList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_USER_DRIVER_DETAILS, {
                agentId: userId
            }, { headers });
            if (res.data.responseCode === 0) {
                setdriverList(res.data.response.agentDriver);

            }

        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };
    const getAllVehicleList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_USER_VEHICLE_DETAILS, {
                agentId: userId
            }, { headers });
            if (res.data.responseCode === 0) {
                setVehicleList(res.data.response.agentVehicle);

            }

        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };
    useEffect(() => {
        getAllDriverList();
        getAllVehicleList();
    }, []);


    const handleImageErr = (e) => {
        e.target.src = flagImg;
    };

    // edit wallet
    const [showWalletModal, setshowWalletModal] = useState(false);
    const handleWalletActionType = () => {
        setshowWalletModal(true);
    };

    const handlewalletAccept = () => {
        setshowWalletModal(false);
    }
    const handleWalletclose = () => {
        setshowWalletModal(false);
    }
    // ======================

    // add edit deriver
    const [showDriverModal, setshowDriverModal] = useState(false);
    const seletedDriverObj = useRef('');
    const actionType = useRef('');
    const handleDriverActionType = (data, type) => {
        seletedDriverObj.current = data;
        // driverObjDetailsAction(data)
        setshowDriverModal(true);
        actionType.current = type;
    };

    const handleDriverAccept = () => {
        seletedDriverObj.current = '';
        setshowDriverModal(false);
        actionType.current = '';
        getAllDriverList();
    }
    const handleDriverclose = () => {
        seletedDriverObj.current = '';
        setshowDriverModal(false);
        actionType.current = '';
    }
    // ======================
    // add edit vehicle
    const [showVehicleModal, setshowVehicleModal] = useState(false);
    const seletedVehicleObj = useRef('');
    const actionVehicleType = useRef('');
    const handleVehicleActionType = (data, type) => {
        seletedVehicleObj.current = data;
        setshowVehicleModal(true);
        actionVehicleType.current = type;
    };

    const handleVehicleAccept = () => {
        seletedVehicleObj.current = '';
        setshowVehicleModal(false);
        actionVehicleType.current = '';
        getAllVehicleList();
    }
    const handleVehicleclose = () => {
        seletedVehicleObj.current = '';
        setshowVehicleModal(false);
        actionVehicleType.current = '';
    }
    // ======================

    // delete function
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const seletedDeleteObj = useRef('');
    const deleteType = useRef('');
    const handleDeleteType = (data, type) => {
        seletedDeleteObj.current = data;
        setShowDeleteModal(true);
        deleteType.current = type;
    };
    const handleDeleteaccept = async () => {
        if (deleteType.current === 'deleteDriver') {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    authorization: token
                };
                const res = await axios.post(API_DELETE_DRIVER, { driverId: seletedDeleteObj.current.driverId }, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    seletedDeleteObj.current = '';
                    setShowDeleteModal(false);
                    deleteType.current = '';
                    getAllDriverList();
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    authorization: token
                };
                const res = await axios.post(API_DELETE_VEHICLE, { agentVehicleId: seletedDeleteObj.current.agentVehicleId }, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    seletedDeleteObj.current = '';
                    setShowDeleteModal(false);
                    deleteType.current = '';
                    getAllVehicleList();
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const handleDeleteclose = () => {
        seletedDeleteObj.current = '';
        setShowDeleteModal(false);
        deleteType.current = '';
    }
    // ===============================


    return (
        <div className="row m-0 mt-3">
            <div className="col-12">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Link to="/app/users" className="page-nav-link"><BsArrowLeft /> Back to user list</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        <div className={`card ${style.customCard}`}>
                            <div className="card-body">
                                <div className="d-flex justify-content-center">
                                    <img
                                        alt="..."
                                        src={userDetailImage && userDetailImage}
                                        onError={handleImageErr}
                                        className={style.borderImg}
                                        // style={{ borderRadius: '50%' }}
                                        width="120" height="120"
                                    ></img>
                                </div>
                                <h5 className={`text-center mt-3 ${style.userNametext}`}>{userObj.current && userObj.current.fullName}</h5>
                                <h6 className={`text-center ${style.userTypeText}`}>{userObj.current && userObj.current.userType}</h6>
                                {/* <h6 className={`text-center m-0 ${style.userTypeText}`}><i class="fas fa-map-marker-alt"></i> Bangalore, India</h6> */}
                            </div>
                            <div className={`card-footer ${style.customCardFooter} ${style.footerPadding}`}>
                                <div className={`w-100 d-flex ${style.postBooking}`}>
                                    <div>
                                        <h5 className={`text-center mb-1 ${style.userNametext} ${style.textSize}`}>{userObj.current && userObj.current.totalPost}</h5>
                                        <h6 className={`text-center ${style.userTypeText}`}>Total Posts</h6>
                                    </div>
                                    <div>
                                        <h5 className={`text-center mb-1 ${style.userNametext} ${style.textSize}`}>{userObj.current && userObj.current.totalBooking}</h5>
                                        <h6 className={`text-center ${style.userTypeText}`}>Total Bookings</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`card ${style.customCard}`}>
                            <div className="card-body">
                                <h6 className={`mb-3 ${style.contactHeadText}`}>Contact Info</h6>
                                <h6 className={style.contactBodyText}>
                                    <span className={style.sideIcons}>
                                        <i class="far fa-envelope" ></i>
                                    </span>
                                    {userObj.current.email ? userObj.current.email : '-'}
                                </h6>
                                <h6 className={style.contactBodyText}><BsTelephone style={{ opacity: '0.5' }} /> {userObj.current.mobileNumber ? `+91 ${userObj.current.mobileNumber}` : '-'}</h6>
                                <h6 className={style.contactBodyText} style={{ textTransform: 'capitalize' }}><i class="fas fa-globe" style={{ opacity: '0.5' }}></i> {userObj.current.companyName ? userObj.current.companyName : '-'}</h6>
                                <h6 className={style.contactBodyText}><i class="fas fa-globe" style={{ opacity: '0.5' }}></i> {userObj.current.licenseNumber ? userObj.current.licenseNumber : '-'}</h6>
                            </div>
                        </div>
                        {/* <div className={`card ${style.customCard}`}>
                            <div className={`card-header ${style.walletHeader}`}>
                                <div className={`${style.manageDiv} ${style.iconDiv}`}>
                                    <i className={`fas fa-money-bill-wave fa-flip-both ${style.customIcon}`}></i>
                                </div>
                                <div className={`${style.manageDiv}`}>
                                    <div className={`w-100 ${style.contentDiv}`}>
                                        <h2>
                                            1000.00
                                        </h2>
                                        <h6>
                                            Wallet Balance
                                        </h6>
                                    </div>
                                </div>
                                <div className={`${style.manageDiv}`}>
                                    <button type="button" className={`btn btn-sm btn-warning`} style={{ color: 'white', boxShadow: 'none', outline: 'none' }} onClick={() => handleWalletActionType()}>
                                        Edit Balance
                                    </button>
                                </div>
                            </div>
                            <div className={`card-header ${style.walletHeader}`}>
                                <h6 className={`m-0`}>History</h6>
                            </div>
                            <div className="card-body" style={{ maxHeight: '300px', overflow: 'auto' }}>
                                <div className="table-responsive">
                                    <table className={`table ${style.modifyTable}`}>
                                        <thead className={`${style.tablehead}`}>
                                            <tr>
                                                <th className={`${style.headText}`}>Date</th>
                                                <th className={`${style.headText}`}>Reason</th>
                                                <th className={`${style.headText}`}>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className={`${style.tableBody}`}>
                                            <tr>
                                                <td className={`${style.bodyText}`}>Date</td>
                                                <td className={`${style.bodyText}`}>Reason</td>
                                                <td className={`${style.bodyText}`}>Amount</td>
                                            </tr>
                                            <tr>
                                                <td className={`${style.bodyText}`}>Date</td>
                                                <td className={`${style.bodyText}`}>Reason</td>
                                                <td className={`${style.bodyText}`}>Amount</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                        <img
                            alt="..."
                            className={style.coverImg}
                            // style={{ borderRadius: '10px', width: '100%' }}
                            height="200"
                            src={coverImg}
                        ></img>

                        <div className="row mt-3">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                <div className={`card ${style.customCard}`}>
                                    <div className={`card-header ${style.customCardHeader}`}>
                                        <h6 className={`${style.headerMaintext}`}>Drivers</h6>
                                        <OverlayTrigger
                                            key={'left'}
                                            placement='left'
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>
                                                    Click to Add
                                                </Tooltip>
                                            }
                                        >
                                            <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDriverActionType('', 'addDriver')}></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="card-body">
                                        {driverList && driverList.length > 0 && driverList.map((ele) => (
                                            <div className={`w-100 d-flex align-items-center mb-3 p-2 ${style.onListHover}`}>
                                                <div>
                                                    <img src={ele.driverImage} onError={handleImageErr} alt="" width="38" height="38"
                                                        className={style.borderImg} />
                                                </div>
                                                <div className="ms-2 w-100 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>

                                                    <div>

                                                        <h6 className={`m-0 ${style.listbodyText}`}>{ele.driverFullName}</h6>

                                                        <h6 className={`m-0 ${style.listlocationText}`}>{ele.city}</h6>

                                                    </div>

                                                    <div>

                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fas fa-edit me-2" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDriverActionType(ele, 'editDriver')}></i>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    Click to Delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteType(ele, 'deleteDriver')}></i>
                                                        </OverlayTrigger>

                                                    </div>

                                                </div>

                                            </div>

                                        ))}

                                        {driverList && driverList.length === 0 &&
                                            <div className="w-100 d-flex justify-content-center align-items-center">
                                                <div>
                                                    <h6>No Drivers.</h6>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                <div className={`card ${style.customCard}`}>
                                    <div className={`card-header ${style.customCardHeader}`}>
                                        <h6 className={`${style.headerMaintext}`}>Vehicle</h6>
                                        <OverlayTrigger
                                            key={'left'}
                                            placement='left'
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>
                                                    Click to Add
                                                </Tooltip>
                                            }
                                        >
                                            <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleVehicleActionType('', 'addVehicle')}></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="card-body">
                                        {vehicleList && vehicleList.length > 0 && vehicleList.map((ele) => (
                                            <div className={`w-100 d-flex align-items-center mb-3 p-2 ${style.onListHover}`}>
                                                <div>
                                                    <img src={ele.vehicleImage} onError={handleImageErr} alt="" width="38" height="38"
                                                        className={style.borderImg} />
                                                </div>
                                                <div className="ms-2 w-100 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>

                                                    <div>

                                                        <h6 className={`m-0 ${style.listbodyTextVehicle}`}>{ele.registrationNumber}</h6>

                                                        <h6 className={`m-0 ${style.listlocationText}`}>{ele.vehicleTypeName}</h6>

                                                    </div>

                                                    <div>

                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fas fa-edit me-2" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleVehicleActionType(ele, 'editVehicle')} ></i>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    Click to Delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteType(ele, 'deleteVehicle')}></i>
                                                        </OverlayTrigger>

                                                    </div>

                                                </div>
                                            </div>
                                        ))}

                                        {vehicleList && vehicleList.length === 0 &&
                                            <div className="w-100 d-flex justify-content-center align-items-center">
                                                <div>
                                                    <h6>No Vehicle.</h6>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddEditDriverComponent
                    show={showDriverModal}
                    onHide={handleDriverclose}
                    onAccept={handleDriverAccept}
                    rowData={seletedDriverObj.current}
                    type={actionType.current}
                />
                <AddEditVehicleComponent
                    show={showVehicleModal}
                    onHide={handleVehicleclose}
                    onAccept={handleVehicleAccept}
                    rowData={seletedVehicleObj.current}
                    type={actionVehicleType.current}
                />
                <DeleteDriverAndVehicleComponent
                    show={showDeleteModal}
                    onHide={handleDeleteclose}
                    onAccept={handleDeleteaccept}
                    type={deleteType.current}
                />

                {/* <EditWalletComponent
                    show={showWalletModal}
                    onHide={handleWalletclose}
                    onAccept={handlewalletAccept}
                /> */}
            </div>
        </div>
    );
}
UserModuleComponent.propTypes = {
    token: PropTypes.string,
    userDetal: PropTypes.any,
    userDetailImage: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    userDetal: state.userMenu.userDetails,
    userDetailImage: state.userMenu.userProfilePic
});

export default connect(mapStateToProps, null)(UserModuleComponent);

