import React, { useState, useEffect } from 'react';
import "./userSubscription.scss";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { usersSubscriptionCountDetailsAction } from '../../redux/userSubscription/userSubscription.actions';

const UsersSubscriptionsComponent = ({ totalCount }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0); 
  useEffect(() => {
    navigate('/app/usersSubscriptions/premium');
    return(() => {
      dispatch(usersSubscriptionCountDetailsAction(0));
    })
  }, []);

  const tabSetting = (data) => {
    setActiveTab(data);
    dispatch(usersSubscriptionCountDetailsAction(totalCount === 0 ? 0 : totalCount));
  }

  return (
    <>
      <div className="row m-0"  >
        <div className="col-12">
          <div className="d-flex">
            <h5 className="customTitleName">Users Subscriptions</h5>
            {/* <p className="ms-3 userCountText">
              {totalCount} users
            </p> */}
          </div>
          <div className="card customCard">
            <div className="card-body">
              <ul className="nav nav-tabs cunstomNavs">
                <li className={`${activeTab === 0 ? 'active' : ''}`}>
                  <Link to="/app/usersSubscriptions/premium" onClick={() => tabSetting(0)}>Premium Users</Link>
                </li>
                <li className={`${activeTab === 1 ? 'active' : ''}`}>
                  <Link to="/app/usersSubscriptions/free" onClick={() => tabSetting(1)}>Free Users</Link>
                </li>

                <li className={`${activeTab === 2 ? 'active' : ''}`}>
                  <Link to="/app/usersSubscriptions/autoSubscription-users" onClick={() => tabSetting(2)}>Auto Subscription Users</Link>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane active">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UsersSubscriptionsComponent.propTypes = {
  token: PropTypes.string,
  totalCount: PropTypes.any
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  totalCount: state.usersSubscription.count
});

export default connect(mapStateToProps, null)(UsersSubscriptionsComponent);
