import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ADD_DRIVER, API_EDIT_DRIVER } from '../../utils/consts/api';
import style from './users.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import flagImg from '../../assets/images/ProfileImage.png';
import { useImageCompression } from '../../components/ImageCompression/useImageCompression';

const AddEditDriverComponent = ({ type, show, onHide, onAccept, token, rowData, userDetal }) => {
    const [driverImageFile, setdriverImageFile] = useState('');
    const [driverImageUrl, setdriverImageUrl] = useState('');

    const [driverLicenseFileFront, setdriverLicenseFileFront] = useState('');
    const [driverLicenseFileBack, setdriverLicenseFileBack] = useState('');
    const [licenseImageUrl, setlicenseImageUrl] = useState('');
    const {data,imageCompressionfn} = useImageCompression();

    const [licenseImageUrlBack, setlicenseImageUrlBack] = useState('');
    // const [driverLicenseUrlBack, setdriverLicenseUrlBack] = useState('');
    // const [file, setFile] = useState([]);
    // const [driverLicenseUrl, setdriverLicenseUrl] = useState('');

    const [editInputs, editSetInputs] = useState({
        drivername: '',
        contactNumber: '',
        email: '',
    });

    const { drivername, contactNumber, email } = editInputs;

    const handleChange = (e) => {
        const { name, value } = e.target;
        editSetInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    let i = 0;
    useEffect(() => {
        if (i === 0) {
            editSetInputs({
                drivername: type === 'editDriver' ? rowData.driverFullName : '',
                contactNumber: type === 'editDriver' ? rowData.driverContactNumber : '',
                email: type === 'editDriver' ? rowData.email : '',
            });
            setdriverImageUrl(type === 'editDriver' ? rowData.driverImage : '');
            setlicenseImageUrl(type === 'editDriver' ? rowData.licenseImage : '');
            setlicenseImageUrlBack(type === 'editDriver' ? rowData.licenseImageBack : '');
            i++;
        }
    }, [type]);


    // let navigate = useNavigate();

    const handleImageErr = (e) => {
        e.target.src = flagImg;
    };

    const initialFileDriverUpload = (event) => {
        event.preventDefault();
        const element = document.getElementById('driverPicInput');
        element.click();
    };

    const onDriverFileChanged = async (event) => {
        const fileData = event.target.files[0];

        if(fileData){
            imageCompressionfn(fileData,'driverImage')
         }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setdriverImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    };
    const initialFileLicenseUploadFront = (event) => {
        event.preventDefault();
        const element = document.getElementById('licensePicInputFront');
        element.click();
    };

    const onLicenseFileChangedFront = async (event) => {
        const fileData = event.target.files[0];

        if(fileData){
            imageCompressionfn(fileData,'lisenceFrontImage');
        }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setlicenseImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    };

    const initialFileLicenseUploadBack = (event) => {
        event.preventDefault();
        const element = document.getElementById('licensePicInputBack');
        element.click();
    };

    const onLicenseFileChangedBack = async (event) => {
        const fileData = event.target.files[0];

        if(fileData){
            imageCompressionfn(fileData,'lisenceBackImage');
        }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setlicenseImageUrlBack(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    };

    const acceptDialogBox = () => {
        onAccept();
        editSetInputs((prevState) => ({
            ...prevState,
            drivername: '',
            contactNumber: '',
            email:'',
        }));

        setdriverImageFile('');
        setdriverLicenseFileFront('');
        setdriverLicenseFileBack('');
        setlicenseImageUrl('');
        setdriverImageUrl('');
        setlicenseImageUrlBack('');
    };

    const hideDialogBox = () => {
        onHide();
        editSetInputs((prevState) => ({
            ...prevState,
            drivername: '',
            contactNumber: '',
            email: '',
        }));

        setdriverImageFile('');
        setdriverLicenseFileFront('');
        setdriverLicenseFileBack('');
        setlicenseImageUrl('');
        setdriverImageUrl('');
        setlicenseImageUrlBack('');
    };

    const onSaveChanged = async (event) => {

        const obj = {
            agentId: type === 'editDriver' ? rowData.agentId : userDetal.agentId,
            driverFullName: drivername,
            driverContactNumber: contactNumber,
            email: email,
            driverImageKey: "key3",
            licenseImageKeyFront: "key4",
            licenseImageKeyBack: "key5"
        }
        if (type === 'editDriver') {
            obj.driverId = rowData.driverId;
        }

        const formDataValue = new FormData();
        formDataValue.append('data', JSON.stringify(obj));
        if (driverImageFile) {
            formDataValue.append('key3', driverImageFile, driverImageFile.name);
        } else {
            formDataValue.append('key3', '');
        }

        if (driverLicenseFileFront) {
            formDataValue.append('key4', driverLicenseFileFront, driverLicenseFileFront.name);
        } else {
            formDataValue.append('key4', '');
        }
        if (driverLicenseFileBack) {
            formDataValue.append('key5', driverLicenseFileBack, driverLicenseFileBack.name);
        } else {
            formDataValue.append('key5', '');
        }

        if (type === 'editDriver') {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_EDIT_DRIVER, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_ADD_DRIVER, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    useEffect(()=>{
        if(data){
         if(data?.imageName === 'driverImage'){
            setdriverImageFile(data?.image);
         }else if(data?.imageName === 'lisenceFrontImage'){
            setdriverLicenseFileFront(data?.image);
         }else if(data?.imageName === 'lisenceBackImage'){
            setdriverLicenseFileBack(data?.image);
         }
        }
     },[data]);


    return (
        <Modal
            size='md'
            show={show}
            onHide={hideDialogBox}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'editDriver' ? 'Edit Driver' : 'Add Driver'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="customModalBody">
                <form autoComplete="off">
                    <div className="row">
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputname1">Name</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputname1" name='drivername' value={drivername} onChange={handleChange} aria-describedby="nameHelp" placeholder="Enter name" />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputnumber1">Phone Number</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputnumber1" maxLength={10} name='contactNumber' value={contactNumber} aria-describedby="numberHelp" placeholder="Enter phone number" onChange={handleChange} onKeyDown={validateNum} />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputEmail1">Driver Email</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputEmail1" name='email' value={email} aria-describedby="emailHelp" placeholder="Enter your email" onChange={handleChange} />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Driver Image</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!driverImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!driverImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileDriverUpload($event)}></i>}
                                            {driverImageUrl && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileDriverUpload($event)}></i>}
                                            <input type="file" id="driverPicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onDriverFileChanged($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {driverImageUrl && <div className="card-body text-center">
                                    <img src={driverImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>License Image 1</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!licenseImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!licenseImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUploadFront($event)}></i>}
                                            {licenseImageUrl && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUploadFront($event)}></i>}
                                            <input type="file" id="licensePicInputFront" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onLicenseFileChangedFront($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {licenseImageUrl && <div className="card-body text-center">
                                    <img src={licenseImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>License Image 2</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!licenseImageUrlBack ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!licenseImageUrlBack && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUploadBack($event)}></i>}
                                            {licenseImageUrlBack && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUploadBack($event)}></i>}
                                            <input type="file" id="licensePicInputBack" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onLicenseFileChangedBack($event)} multiple />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {licenseImageUrlBack && <div className="card-body text-center">
                                    <img src={licenseImageUrlBack} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        {/* <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>License Image</h6>
                                    {/* <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!driverLicenseUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    > */}
                        {/* <div>
                                        <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUpload($event)}></i> */}

                        {/* {!driverLicenseUrl && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileLicenseUpload($event)}></i>} */}
                        {/* <input type="file" id="licensePicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onLicenseFileChanged($event)} multiple />
                                    </div> */}

                        {/* </OverlayTrigger> */}
                        {/* </div>
                                <div className="card-body text-center">
                                    {(fileArray.current || []).map((url, index) =>
                                        <img src={url} key={index} className={style.modalImage} onError={handleImageErr} alt="" width="50" height="50" style={{ margin: '20px' }} />
                                    )}  </div>
                            </div>
                        </div> */}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={hideDialogBox}>Cancel</button>
                <button className='btn customButton acceptButton' onClick={onSaveChanged}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

AddEditDriverComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any,
    rowData: PropTypes.any,
    userDetal: PropTypes.any,
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept,
    rowData: props.rowData,
    userDetal: state.userMenu.userDetails,
});

export default connect(mapStateToProps, null)(AddEditDriverComponent);