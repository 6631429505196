import React, { useState } from 'react';
import styles from "../../../views/B2B Payments/payment.module.scss"
import {  OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import DeleteuserComponent from "../../../views/B2B Payments/hold-user-modal"





const PaymentProcess = (props) => {

    const [modalShow, setModalShow] = useState(false);

   
    return (
        <>
        <div  className={`${styles.buttonDiv}`}>
        {props.data.Status === "processing" ? (
          <button
            className={`${styles.button}`}
            onClick={() =>
              props.context.handleDeleteOpen(props.data.actualData)
            }
          >
           <p> Hold</p>
          </button>
        ) : props.data.Status === "hold" ? (
          <button
            className={`${styles.paymentHoldButton}`}
            
            disabled
          >
          <p>  Payment on Hold{" "}</p>
          </button>
        ) : props.data.Status === "paid" ? (
          <p className={`${styles.paidToVendor}`}>
            Paid to Vendor{" "}
          </p>
        ) : props.data.Status === "refunded" ? (
          <p className={`${styles.refunded}`}>
            {" "}
            Refunded{" "}
          </p>
        ) : props.data.Status === "success" ? (
          <p className={`${styles.success}`}>
            {" "}
            Completed{" "}
          </p>
        ) : props.data.Status === "cancelled" ? (
          <p className={`${styles.cancelled}`}>
            {" "}
            Cancelled{" "}
          </p>
        ) :
          props.data.Status === "failed" ?
            <div
              className={`${styles.paymentFailedDiv}`}
            >
              <p
                style={{ marginBottom: "0px" }}
                className={`${styles.paymentFailedText}`}
              >
                Payment failed
              </p>
              <span
                className="bodyText text-center"
                style={{ marginLeft: "15px" }}
              >
                <OverlayTrigger
                  key={"top"}
                  placement="left"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header as="h3">
                        Payment Failed Cause
                      </Popover.Header>
                      <Popover.Body>
                        {props.paymentFailedReason
                          ? props.paymentFailedReason
                          : "Reason is not specify"}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i
                    class={`far fa-eye ${styles.actionBtn}`}
                  ></i>
                </OverlayTrigger>
              </span>
              <span>
                <OverlayTrigger
                  key={"bottom"}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      Click to edit
                    </Tooltip>
                  }
                >
                  <span className={styles.actionBtn}>
                    <i
                      class="fas fa-edit"
                      onClick={() =>
                        props.context.handleEditFunc(props.data.actualData.bookingId)
                      }
                    ></i>
                  </span>
                </OverlayTrigger>
              </span>
            </div>
            : "-"
        }
      </div>
         <DeleteuserComponent
         show={modalShow}
         onHide={props.context.handleDeleteClose}
         onAccept={props.context.handleDeleteAccept}
       />   
       </>
    );
}


export default PaymentProcess;
