/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './vehicle.module.scss';
import axios from 'axios';
import { API_VEHICLE_LIST, API_VEHICLE_DELETE } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteVehicleComponent from './delete-modal';
import AddVehicleComponent from './add-modal';
import AgTable from '../../components/Table/AgTable';
import { VehicleTypeFields } from '../../components/Table/demoData';
import SkeletonLoader from '../../components/Loader';

const VehicleTypeComponent = ({ token }) => {
    const [vehicleTypeList, setvehicleTypeList] = useState([]);
    const [spinner,setSpinner]=useState(false);
    // const [supportListBackUp, setsupportListBackUp] = useState([]);
    // const pageLimit = useRef(5);
    // const pageNo = useRef(0);
    // const [pageNoMax] = useState(10000000);
    // const [cacheNextUserList, setcacheNextUserList] = useState([]);
    // const [cachePrevUserList, setcachePrevUserList] = useState([]);

    // const getNextPrevSupportList = async (whichPage = 'next') => {
    //     whichPage = pageNo.current === 0 ? 'next' : whichPage;

    //     let nextPageNo = pageNo.current;
    //     if (whichPage === 'next') {
    //         nextPageNo = nextPageNo + 1;
    //     } else {
    //         nextPageNo = nextPageNo - 1;
    //     }
    //     try {
    //         const headers = {
    //             'Content-Type': 'application/json',
    //             authorization: token
    //         };
    //         const res = await axios.post(API_SUPPORT_LIST, { page: nextPageNo, size: pageLimit.current }, { headers });
    //         if (res.data.responseCode === 0) {
    //             if (whichPage === 'next') {
    //                 setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.ticketList : []);
    //             } else {
    //                 setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.ticketList : []);
    //             }
    //         } else {
    //             toast.error(res.data.errorMsg, {
    //                 position: "top-right",
    //                 autoClose: 4000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //     } catch (err) {
    //         toast.error('Internal server error.', {
    //             position: "top-right",
    //             autoClose: 4000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // }


    const getAllVehicleList = async () => {
            setSpinner(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_VEHICLE_LIST, {}, { headers });
            if (res.data.responseCode === 0) {
                setvehicleTypeList(res.data.response.vehicleTypes);
                setSpinner(false);
                // setsupportListBackUp(res.data.response.ticketList);
                // if (res.data.response.ticketList.length === pageLimit.current && cacheNextUserList.length === 0) {
                //     getNextPrevSupportList();
                // }
            } else {
                setSpinner(false);

                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setSpinner(false);
            
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    // const nextCacheSet = () => {
    //     if (cacheNextUserList.length > 0) {
    //         setcachePrevUserList(supportList)
    //         //   this.cachePrevUserList = this.apiAllUsers;

    //         setsupportList(cacheNextUserList);
    //         setsupportListBackUp(cacheNextUserList);

    //         setcacheNextUserList([]);
    //         getNextPrevSupportList('next');
    //     } else {
    //         setcacheNextUserList([]);
    //         getAllSupportList();
    //     }
    // }

    // const prevCacheSet = () => {
    //     if (cachePrevUserList.length > 0) {
    //         setcacheNextUserList(supportList);
    //         //   this.cacheNextUserList = this.apiAllUsers;
    //         setsupportList(cachePrevUserList);
    //         setsupportListBackUp(cachePrevUserList);
    //         setcachePrevUserList([]);
    //         getNextPrevSupportList('prev');
    //     } else {
    //         setcachePrevUserList([]);
    //         getAllSupportList();
    //     }
    // }


    let i = 0;
    useEffect(() => {
        if (i === 0) {
            getAllVehicleList();
            i++;
        }
    }, []);

    // const resetCache = () => {
    //     pageNo.current = 0;
    //     setcacheNextUserList([]);
    //     setcachePrevUserList([]);
    //     i=0;
    // }




    // const nextPrev = (val) => {

    //     const page = pageNo.current + 1;

    //     if (val === 'next') {
    //         if (page < pageNoMax) {
    //             pageNo.current = pageNo.current + 1;
    //             nextCacheSet();
    //         }
    //     } else {
    //         if (page > 1) {
    //             pageNo.current = pageNo.current - 1;
    //             prevCacheSet();
    //         }
    //     }

    // }

    // const entriesPerPage = (e) => {
    //     pageLimit.current = parseInt(e.target.value, 10);
    //     resetCache();
    // }

    // const handleImageErr = (e) => {
    //     e.target.src = dummy;
    // };

    const [modalAddShow, setModalAddShow] = useState(false);

    const handleAddOpen = () => {
        setModalAddShow(true);
    }

    const handleAddAccept = () => {
        setModalAddShow(false);
        getAllVehicleList();
    }

    const handleAddclose = () => {
        setModalAddShow(false);
    }

    const [modalShow, setModalShow] = useState(false);
    const seletedObj = useRef('');

    const handleDeleteOpen = (seldata) => {
       
        seletedObj.current = seldata;
        setModalShow(true);
    }

    const handleDeleteaccept = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_VEHICLE_DELETE, { vehicleTypeId: seletedObj.current.vehicleTypeId }, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                seletedObj.current = '';
                setModalShow(false);
                getAllVehicleList();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteclose = () => {
        setModalShow(false);
    }

    
    return (
        <>
<div 
            //    className="row m-0"  
            className={styles.vehicleContainer}
            >
    <div style={{height:"90vh"}}>
                    <div className={styles.textHeader}>
                        <h1 className='customTitleName'>Vehicle Type</h1>
                        <button type="button" onClick={() => handleAddOpen()} className={`btn ${styles.addButton}`}><i class="fas fa-plus-circle"></i> Add Vehicle</button>
                    </div>
                {/* <div className="col-12"> */}
                    {/* <div className={`card ${styles.customCard}`}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12"> */}
                                    
                                    {/* <div className="w-100 customPginationDiv">
                                    this div was commented already
                                        <h6 className="customText">Items per page</h6>
                                        <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                        <button className="btn customButtons" disabled={pageLimit.current >= supportList.length && cacheNextUserList.length === 0} onClick={() => nextPrev('next')}>
                                            <i className="fas fa-angle-right"></i></button>
                                    </div> */}

                                {/* </div> */}
                                {/* <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">Vehicle Type Name</th>
                                                    <th className="headText text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                                {vehicleTypeList && vehicleTypeList.length > 0 && vehicleTypeList.map((ele) => (<tr>
                                                    <td className="bodyText">{ele.vehicleTypeName}</td>
                                                    <td className="bodyText text-center">
                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Delete Vehicle
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" onClick={() => handleDeleteOpen(ele)} style={{ opacity: '0.5', cursor: 'pointer' }}></i>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                // </div> */}
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}



                    {spinner&&vehicleTypeList.length==0?
                    <SkeletonLoader/> :
                    <AgTable
                    section={"vehicleType"}
                    rowData={vehicleTypeList}
                    colDefs={VehicleTypeFields}
                    handleDeleteOpen={handleDeleteOpen}
                    />
        }
    </div>

</div>

            <DeleteVehicleComponent
                show={modalShow}
                onHide={handleDeleteclose}
                onAccept={handleDeleteaccept}
            />

            <AddVehicleComponent
                show={modalAddShow}
                onHide={handleAddclose}
                onAccept={handleAddAccept}
            />
        </>
    );
};

VehicleTypeComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(VehicleTypeComponent);