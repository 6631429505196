import React, { useEffect, useRef, useState } from 'react';
import styles from "../../../views/users/users.module.scss";
import { Form, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PinDialogue from '../../PinDialogue';
import DeleteuserComponent from "../../../views/users/delete-user-modal"
import { toast } from 'react-toastify';
const UserActions = (props) => {
    
    const [show, setShow] = useState(false);
    const inputRef=useRef(null);
    const handleCheck=()=>{
        setShow(prev=>!prev)
    }

    const handleClose = async (handler) => {
        if(inputRef.current.value && inputRef.current.value.length>=6 ){
          const inputValue= inputRef.current.value;
        //   setIsDownloading(true);
          try{
            await handler;
          }catch(err){
            toast.error(err);
          }finally{
            // setIsDownloading(false);
          }
        }else if(!inputRef.current.value){
          toast.error('Field is empty')
        }else if(inputRef.current.value.length < 6 ){
          toast.error('Pin should be atleast 6 digits')
        }
        // setShow(false)
      };

      const userStatus=()=>{

      }
      useEffect(()=>{
        if(show && inputRef.current){
          inputRef.current.focus();
        }
      },[show]);
    return (
        <td className="bodyText text-center">
            <OverlayTrigger
            key={'top'}
            placement='top'
            overlay={
            <Tooltip id={`tooltip-top`}>
            Click to view
            </Tooltip>
            }>
                {/* <Link to={`/app/users/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                    <span className={styles.actionBtn}>
                    <i class="far fa-eye" onClick={() => props.context.handleViewFunc(props.data.actualData)}></i>
                    </span>
                       {/* </Link> */}
                    </OverlayTrigger>

            <OverlayTrigger
                key={'bottom'}
                placement='bottom'
                overlay={
                <Tooltip id={`tooltip-bottom`}>
                    Click to edit
                </Tooltip>}>
                    {/* <Link to={`/app/usersEdit/${ele.agentId}`} style={{ color: '#272b41' }}> */}
            <span className={styles.actionBtn}>
                <i class="fas fa-edit" onClick={() => props.context.handleEditFunc(props.data.actualData)}></i>
             </span>
                    {/* </Link> */}
            </OverlayTrigger>
            <button 
            type="button" 
            class={(props.data.actualData.enabledStatus === 1)&&"btn btn-success" || props.data.actualData.enabledStatus === 0 &&"btn btn-danger" }  
            onClick={handleCheck}>{
                props.data.actualData.enabledStatus === 1
                ? "Enabled"
                : props.data.actualData.enabledStatus === 0
                ? "Disabled"
                : ""}</button>
            

            <Modal
            size='md'
            show={show}
            centered
            backdrop="static"
            keyboard={true}
            className="customModal"
        >
            <Modal.Header closeButton>
                {/* <Modal.Title>
                    Enter Pin
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            <h5>
            {`Are you sure you want to ${
                props.data.actualData.enabledStatus === 1
                ? "Disable"
                : props.data.actualData.enabledStatus === 0
                ? "Enable"
                : ""
            } the user?`}
            </h5>
            <br />
            <InputGroup size="md" autoComplete={'none'} aria-autocomplete='none'>
            <Form.Control 
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            placeholder='Enter Pin'
            type="password"
            ref={inputRef}
            autoComplete="none"
            />
      </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={()=>setShow(false)}>cancel</button>
                <button className='btn customButton acceptButton' onClick={()=>handleClose(props.context.handleDeleteOpen(props.data.actualData , inputRef.current.value))}>Apply</button>
            </Modal.Footer>
        </Modal>
        </td>
    );
}

export default UserActions;
