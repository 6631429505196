import UserMenuDetailsTypes from './userMenu.types';

export const userMenuDetails = (data) => ({
  type: UserMenuDetailsTypes.USER_DETAILS,
  payload: data
});

export const userDetailsSetImage = (data) => ({
  type: UserMenuDetailsTypes.USER_DETAILS_IMAGE,
  payload: data
});