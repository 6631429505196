import React, { useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { FiUser } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './users.module.scss';
import flagImg from '../../assets/images/ProfileImage.png';
import coverImg from '../../assets/images/Cover imagenew.png';
import { API_CRM_UPDATECRMUSERINFO, API_UPDATE_AGENT, API_UPDATE_AGENT_PROFILE } from '../../utils/consts/api';
import { userDetailsSetImage } from '../../redux/userMenu/userMenu.actions';

function CRMUserDetailsComponent({ token, userDetal }) {
    
    const [showeditProfile, setshoweditProfile] = useState(true);
    // const [showaccSetting, setshowaccSetting] = useState(false);
    const userObj = useRef(userDetal);
    const type = useRef('editProfile');

    const [inputs, setInputs] = useState({
        name: userObj.current.fullName,
        contactNumber: userObj.current.mobileNumber,
        fbUrl: userObj.current.fbUrl,
        justDialUrl: userObj.current.justDialUrl,
        googleUrl: userObj.current.googleUrl,
        tripAdvisorUrl: userObj.current.tripAdvisorUrl,
        userType: userObj.current.userType,
        emailId: userObj.current.emailId,
        companyName: userObj.current.companyName
    });

    const { name, contactNumber, emailId, companyName, fbUrl, tripAdvisorUrl, googleUrl, justDialUrl, userType } = inputs;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleImageErr = (e) => {
        e.target.src = flagImg;
    };

    const handleType = (data) => {
        type.current = data;
        if (data === 'editProfile') {
            setshoweditProfile(true);
            // setshowaccSetting(false);
        } else {
            // setshoweditProfile(false);
            // setshowaccSetting(true);
        }
    };

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    const handleReset = () => {
       
        navigate(`/crm/users`);
        
        setInputs((prevState) => ({
            ...prevState,
            name: userObj.current.fullName ? userObj.current.fullName : '',
            contactNumber: userObj.current.mobileNumber ? userObj.current.mobileNumber : '',
            fbUrl: userObj.current.fbUrl ? userObj.current.fbUrl : '',
            justDialUrl: userObj.current.justDialUrl ? userObj.current.justDialUrl : '',
            googleUrl: userObj.current.googleUrl ? userObj.current.googleUrl : '',
            tripAdvisorUrl: userObj.current.tripAdvisorUrl ? userObj.current.tripAdvisorUrl : '',
            userType: userObj.current.userType ? userObj.current.userType : '',
            emailId: userObj.current.emailId ? userObj.current.emailId : '',
            companyName: userObj.current.companyName ? userObj.current.companyName : ''
        }));
    };

    let navigate = useNavigate();

    const updateProfile = async () => {
        const obj = {
            crmAgentId: userObj.current.crmAgentId,
            mobileNumber: contactNumber,
            fullName: name,
            companyName: companyName,
            emailId: emailId,
            fbUrl: fbUrl,
            googleUrl: googleUrl,
            tripAdvisorUrl: tripAdvisorUrl,
            justDialUrl: justDialUrl
        }

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CRM_UPDATECRMUSERINFO, obj, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(`/crm/users`);
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    // image worke
    // const initialFileUpload = (event) => {
    //     event.preventDefault();
    //     const element = document.getElementById('profilePicInput');
    //     element.click();
    // };

    // const onFileChanged = async (event) => {
    //     const fileData = event.target.files[0];

    //     if (fileData) {
    //         const obj = {
    //             agentId: userObj.current.agentId,
    //         }

    //         const formDataValue = new FormData();
    //         formDataValue.append('data', JSON.stringify(obj));
    //         formDataValue.append('userImage', fileData, fileData.name);

    //         try {
    //             const headers = {
    //                 authorization: token
    //             };
    //             const res = await axios.post(API_UPDATE_AGENT_PROFILE, formDataValue, { headers });
    //             if (res.data.responseCode === 0) {
    //                 toast.success(res.data.successMsg, {
    //                     position: "top-right",
    //                     autoClose: 1000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //                 // navigate(`/app/users`);
    //                 const reader = new FileReader();

    //                 reader.onload = (event) => {

    //                     userDetailsSetImage(reader.result);
    //                 };

    //                 reader.readAsDataURL(fileData);
    //             } else {
    //                 toast.error(res.data.errorMsg, {
    //                     position: "top-right",
    //                     autoClose: 1000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             }
    //         } catch (err) {
    //             toast.error('Internal server error.', {
    //                 position: "top-right",
    //                 autoClose: 1000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //     }
    // }

    return (
        <div className="row m-0 mt-3">
            <div className="col-12">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Link to="/crm/users" className="page-nav-link"><BsArrowLeft /> Back to CRM user list</Link>
                    </div>
                </div>
                <div className="row">

                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        <div className={`card ${style.customCard}`}>
                            <div className="card-body">
                                <div className="d-flex justify-content-center">
                                    {/* <div className={`${style.cameraIcon}`}><i class="fas fa-camera"></i></div> */}
                                    <div className={style.imageContainer}>
                                        <img
                                            alt="..."
                                            src={flagImg}
                                            onError={handleImageErr}
                                            className={style.profileImage}
                                        ></img>
                                    </div>
                                </div>
                                <h5 className={`text-center mt-3 ${style.userNametext}`}>{userObj.current && userObj.current.fullName}</h5>
                                <h6 className={`text-center m-0 ${style.userTypeText}`}>{userObj.current && userObj.current.userType}</h6>
                            </div>
                            <div className={`card-footer ${style.customCardFooter}`}>
                                <button className={`btn w-100 ${style.customButton} ` + (showeditProfile ? `${style.focused}` : ``)} onClick={() => handleType('editProfile')}><FiUser className={style.posEditAcc} />Edit Profile</button>
                                {/* <button className={`btn w-100 ${style.customButton} ` + (showaccSetting ? `${style.focused}` : ``)} onClick={() => handleType('accSetting')}><IoMdSettings className={style.posEditAcc} />Account Settings</button> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 col-lg-8 col-xl-8">
                        <img
                            alt="..."
                            className={style.coverImg}
                            height="210"
                            src={coverImg}
                        ></img>

                        <div className={`card ${style.customCard} mt-3`}>
                            <div className={`card-header ${style.customCardHeader}`}>
                                <h6 className={`${style.headerMaintext}`}>{type.current === 'editProfile' ? 'Edit Profile' : 'Account Settings'}</h6>
                                <h6 className={`${style.headersecondtext} m-0`}>{type.current === 'editProfile' ? 'Set up your personal information' : 'Update your username and manage your account'}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    {type.current === 'editProfile' && <div className="col-12 col-md-10 col-lg-6 col-xl-6">
                                        <div className="w-100">
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputname1">Name</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputname1" name='name' value={name} onChange={handleChange} aria-describedby="nameHelp" placeholder="Enter name" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputnumber1">Phone Number</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputnumber1" maxLength={10} readOnly name='contactNumber' value={contactNumber} onChange={handleChange} onKeyDown={validateNum} aria-describedby="numberHelp" placeholder="Enter phone number" />
                                            </div>
                                            
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputCompany1">Company Name</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputCompany1" name='companyName' value={companyName} onChange={handleChange} aria-describedby="companyHelp" placeholder="Enter company name" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputemail1">Email</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputemail1" name='emailId' value={emailId} onChange={handleChange} aria-describedby="emailHelp" placeholder="Enter email" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputfbUrl">FB Url</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputfbUrl" name='fbUrl' value={fbUrl} onChange={handleChange} aria-describedby="fbUrlHelp" placeholder="Enter FB Url" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputJustDialUrl">JustDial Url</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputJustDialUrl" name='justDialUrl' value={justDialUrl} onChange={handleChange} aria-describedby="justDialUrlHelp" placeholder="Enter Just Dial Url" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputGoogleUrl">Google Url</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputGoogleUrl" name='googleUrl' value={googleUrl} onChange={handleChange} aria-describedby="userHelp" placeholder="Enter Google Url" />
                                            </div>
                                            <div class="form-group mb-3">
                                                <label htmlFor="exampleInputTripAdvisorUrl">Trip Advisor Url</label>
                                                <input type="text" class="form-control customloginFieldBorder" id="exampleInputTripAdvisorUrl" name='tripAdvisorUrl' value={tripAdvisorUrl} onChange={handleChange} aria-describedby="userHelp" placeholder="Enter Trip Advisor Url" />
                                            </div>
                                            <button type="submit" class="btn customButtonActive userProfile" onClick={updateProfile}>Update Profile</button>
                                            <button type="submit" class="btn customButtonCancle ms-3" onClick={handleReset}>Cancel</button>
                                        </div>
                                    </div>}

                                    {/* {type.current === 'accSetting' && <div className="col-12 col-md-10 col-lg-8 col-xl-8 mt-2">
                                        <div className="w-100">
                                            <div className="d-flex mb-5" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    <h5 className={`${style.userNametext}`}>Close Account</h5>
                                                    <h6 className={`m-0 ${style.userTypeText}`}>Delete your account and account data</h6>
                                                </div>
                                                <span class={`badge ${style.closeAccount}`}>Close Account</span>
                                            </div>
                                            <button type="submit" class="btn customButtonActive userProfile">Save Changes</button>
                                            <button type="submit" class="btn customButtonCancle ms-3">Cancel</button>
                                        </div>
                                    </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CRMUserDetailsComponent.propTypes = {
    token: PropTypes.string,
    userDetal: PropTypes.any,
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    userDetal: state.userMenu.userDetails,
});

export default connect(mapStateToProps, { userDetailsSetImage })(CRMUserDetailsComponent);


