/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './users.module.scss';
import axios from 'axios';
import { API_AGENT_LIST, API_APP_GENRATEREPORT, API_CRM_DELETECRMAGENT, API_CRM_GENRATECRMREPORT, API_CRM_GETALLCRMUSERS, API_DELETE_AGENT } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteuserComponent from './delete-user-modal';
import { userMenuDetails } from '../../redux/userMenu/userMenu.actions'
import * as moment from 'moment';

const CRMListComponent = ({ token, userMenuDetails }) => {
    const [userList, setuserList] = useState([]);
    const [usertListBackUp, setuserListBackUp] = useState([]);
    const pageLimit = useRef(5);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(10000000);
    const [cacheNextUserList, setcacheNextUserList] = useState([]);
    const [cachePrevUserList, setcachePrevUserList] = useState([]);

    // To get list of CRM users which may be previous or next depends on user click button.

    const getNextPrevUsersList = async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CRM_GETALLCRMUSERS, { page: nextPageNo, size: pageLimit.current, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                } else {
                    setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    // To get list of CRM users.

    const getAllUsersList = async (searchValue) => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CRM_GETALLCRMUSERS, { page: pageNo.current, size: pageLimit.current, searchString: searchValue !== undefined ? searchValue : query }, { headers });
            if (res.data.responseCode === 0) {
                setuserList(res.data.response.agentDetails);
                setuserListBackUp(res.data.response.agentDetails);
                if (res.data.response.agentDetails.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevUsersList();
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    // Methods called related to selection of previous and next CRM user list.

    const nextCacheSet = () => {
        if (cacheNextUserList.length > 0) {
            setcachePrevUserList(userList)
            setuserList(cacheNextUserList);
            setuserListBackUp(cacheNextUserList);
            setcacheNextUserList([]);
            getNextPrevUsersList('next');
        } else {
            setcacheNextUserList([]);
            getAllUsersList();
        }
    }

    const prevCacheSet = () => {
        if (cachePrevUserList.length > 0) {
            setcacheNextUserList(userList);
            setuserList(cachePrevUserList);
            setuserListBackUp(cachePrevUserList);
            setcachePrevUserList([]);
            getNextPrevUsersList('prev');
        } else {
            setcachePrevUserList([]);
            getAllUsersList();
        }
    }

    useEffect(() => {
        getAllUsersList();
    }, []);


    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextUserList([]);
        setcachePrevUserList([]);
    };


    const nextPrev = (val) => {
        const page = pageNo.current + 1;

        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    };

    const entriesPerPage = (e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        getAllUsersList();
        getNextPrevUsersList();
        resetCache();
    };

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };

    const [modalShow, setModalShow] = useState(false);
    const seletedObj = useRef('');

    let navigate = useNavigate();

    // Methods related to navigation to view and edit pages.

    const handleViewFunc = (seldata) => {
        userMenuDetails(seldata);
        navigate(`/crm/user/${seldata.agentId}`);
    };


    const handleEditFunc = (seldata) => {
        userMenuDetails(seldata);
        navigate(`/crm/userEdit/${seldata.crmAgentId}`);
    };

    // Functions related to deletion of CRM Users

    const handleDeleteOpen = (seldata) => {
        seletedObj.current = seldata;
        setModalShow(true);
    }

    const handleDeleteaccept = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CRM_DELETECRMAGENT, { crmAgentId: seletedObj.current.crmAgentId }, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                seletedObj.current = '';
                setModalShow(false);
                resetCache();
                getAllUsersList();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteclose = () => {
        seletedObj.current = '';
        setModalShow(false);
    }

    const [query, setQuery] = useState('');

    const handleSubmit = async (searchValue) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CRM_GETALLCRMUSERS, { page: 0, size: 5, searchString: searchValue }, { headers });
            if (res.data.responseCode === 0) {
                setuserList(res.data.response.agentDetails);
                setuserListBackUp(res.data.response.agentDetails);
                if (res.data.response.agentDetails.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevUsersList();
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const searchItems = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length >= 3) {
            handleSubmit(e.target.value);
        } else if (e.target.value.length === 0) {
            resetCache();
            getAllUsersList(e.target.value);
        }
    };

    // Method related to export of excel Sheet
    const [showLoader, setShowLoader] = useState(false);
    const handleSaveAsExcel = async () => {
        setShowLoader(true);
        try {
            const headers = {
                "Content-Type": "application/json",
                authorization: token,
            };
            const res = await axios.post(
                API_CRM_GENRATECRMREPORT, {},
                { headers }
            );
            if (res.data.responseCode === 0) {
                const link = document.createElement('a');
                link.href = res.data.downloadUrl;;
                document.body.appendChild(link);
                link.click();
                setShowLoader(false);
            } else {
                setShowLoader(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setShowLoader(false);
            toast.error("Internal server error.", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <div className='d-flex'>
                        <h5 className="customTitleName">CRM User List</h5>
                        <p className={`ms-3 ${styles.userSearchText}`}>{userList.length} users</p>
                        <div className="col-md-8">
                            <div className="d-flex form-inputs">
                                <input className={`form-control ${styles.searchBar}`} type="text"
                                    onChange={searchItems}
                                    placeholder="Search..."
                                    value={query}
                                />
                                <i className="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '22px', cursor: 'pointer' }} onClick={() => handleSubmit(query)}></i>
                            </div>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <button className={`btn customButtonActive ${styles.customDisabledBtn}`} style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={showLoader} onClick={handleSaveAsExcel}>Export Data
                                                {showLoader ? <div className={`buttonLoader`}></div> : null}
                                            </button>
                                        </div>
                                        <div className="w-100 customPginationDiv">
                                            <h6 className="customText">Items per page</h6>
                                            <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                            <button className="btn customButtons" disabled={pageLimit.current >= userList.length && cacheNextUserList.length === 0 || userList.length < 5} onClick={() => nextPrev('next')}>
                                                <i className="fas fa-angle-right"></i></button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">User</th>
                                                    <th className="headText">Email</th>
                                                    <th className="headText">Mobile Number</th>
                                                    <th className="headText">Company</th>
                                                    {/* <th className="headText">User Type</th> */}
                                                    <th className="headText">Join Date</th>
                                                    <th className="headText">Status</th>
                                                    <th className="headText text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">

                                                {userList && userList.length > 0 && userList.map((ele) => (<tr key={ele.crmAgentId}>
                                                    <td>
                                                        <div className="d-flex w-100 align-items-center" key={ele.crmAgentId}>
                                                            <div>
                                                                <img src={ele.image} onError={handleImageErr} alt="" width="38" height="38"
                                                                    style={{ borderRadius: '50%' }} />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h6 className="m-0 bodyText">{ele.fullName}</h6>
                                                                {/* <h6 className="m-0 locationText">Bangalore</h6> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={styles.emailText}>{ele.emailId ? ele.emailId : '-'}</td>
                                                    <td className="bodyText">{ele.mobileNumber ? ele.mobileNumber : '-'}</td>
                                                    <td className="bodyText">{ele.companyName ? ele.companyName : '-'}</td>
                                                    <td className="bodyText">{moment(new Date(ele.createTs)).format('MMMM D, Y')}</td>

                                                    <td style={ele.status === "active" ? { color: "green" } : { color: "#FF4D4F" }}>
                                                        {ele.status}
                                                    </td>
                                                    <td className="bodyText text-center">
                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to view
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {/* <Link to={`/app/users/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                                                            <span className={styles.actionBtn}>
                                                                <i className="far fa-eye" onClick={() => handleViewFunc(ele)}></i>
                                                            </span>
                                                            {/* </Link> */}
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    Click to edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {/* <Link to={`/app/usersEdit/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                                                            <span className={styles.actionBtn}>
                                                                <i className="fas fa-edit" onClick={() => handleEditFunc(ele)}></i>
                                                            </span>
                                                            {/* </Link> */}
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    Click to delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteOpen(ele)}></i>
                                                        </OverlayTrigger>


                                                    </td>
                                                </tr>))}
                                                {userList && userList.length === 0 &&
                                                    <div className="text-center w-100">
                                                        <h4>No Data Found</h4>
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DeleteuserComponent
                show={modalShow}
                onHide={handleDeleteclose}
                onAccept={handleDeleteaccept}
            />
        </>
    );
};

CRMListComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, { userMenuDetails })(CRMListComponent);