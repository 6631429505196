import UsersSubscriptionTypes from './userSubscription.types';

const INITIAL_STATE = {
  count: 0
};

const usersSubscriptionCountObjReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersSubscriptionTypes.USERS_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};

export default usersSubscriptionCountObjReducer;
