import React from 'react';
import styles from "../../../views/B2C Payments/payment.module.scss";
import { BiRupee } from 'react-icons/bi';

const CommissionB2C = (props) => {
    const commission=props.data.actualData.taxiSanchalakCommission|| "-";
    return (
        <div className={`${styles.amountDiv}`}>
        <BiRupee style={{ marginTop: "-1px" }} />
        { commission }
      </div>
    );
}

export default CommissionB2C;
