/* eslint-disable import/no-cycle */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import UserActionTypes from './user.types';
import { API_LOGIN_USER } from '../../utils/consts/api';
import axios from 'axios';
import {
  userSignInSuccess,
  userSignInFailure
} from './user.actions';
import { toast } from 'react-toastify';

const userSignInStartAPI = async (payload) => {
  try {
    const obj = {
      emailId: payload.email,
      password: payload.password,
    };
    const headers = {
      'Content-Type': 'application/json',
      'authorization': ''
    };
    const res = await axios.post(API_LOGIN_USER, obj, headers);
    return res.data;
  } catch (err) {
    return err;
  }
};

export function* userSignInStart({ payload }) {
  try {
    const response = yield call(userSignInStartAPI, payload);
    if (response.responseCode === 0) {
      yield put(userSignInSuccess(response));
    } else {
      toast.error(response.errorMsg, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(userSignInFailure(response));
    }
  } catch (error) {
    toast.error('Internal server error.', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(userSignInFailure(error));
  }
}

export function* onUserSignInStatt() {
  yield takeLatest(UserActionTypes.SIGN_IN_START, userSignInStart);
}


export function* userSagas() {
  yield all([
    call(onUserSignInStatt)
  ]);
}
