import UserMenuDetailsTypes from './userMenu.types';

const INITIAL_STATE = {
  userDetails: null,
  userProfilePic: null
};

const userMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserMenuDetailsTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        userProfilePic: action.payload.image
      };
      case UserMenuDetailsTypes.USER_DETAILS_IMAGE:
        return {
          ...state,
          userProfilePic: action.payload
        };
    default:
      return state;
  }
};

export default userMenuReducer;
