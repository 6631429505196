import React from 'react';
import style from "../../../views/B2B Payments/payment.module.scss"
import RS from "../../../assets/images/RS.png";


const TotalAmount = (props) => {
    

    const amount=props.data.actualData.bookingCommission;
    return (
        <div className={style.amount}>
            <img src={RS} alt="amnt"/>
            <h4>₹ {amount}</h4>
        </div>
    );
}

export default TotalAmount;
