import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

const DeleteDriverAndVehicleComponent = ({ type, show, onHide, onAccept }) => {
    return (
        <Modal
            size='md'
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'deleteDriver' &&
                        <h6> Delete Driver</h6>
                    }
                    {type === 'deleteVehicle' &&
                        <h6> Delete Vehicle </h6>
                    }
                     {type === 'deleteCustomer' &&
                        <h6> Delete Customer </h6>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'deleteDriver' &&
                    <h5> Are you sure you want to delete the driver ?</h5>}
                {type === 'deleteVehicle' &&
                    <h5> Are you sure you want to delete the vehicle ?</h5>
                }
                 {type === 'deleteCustomer' &&
                    <h5> Are you sure you want to delete the customer ?</h5>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={onHide}>No</button>
                <button className='btn customButton acceptButton' onClick={onAccept}>Yes</button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteDriverAndVehicleComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any,
    type: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept,
    type: props.type
});

export default connect(mapStateToProps, null)(DeleteDriverAndVehicleComponent);