import React, { useEffect, useState } from 'react';
import ListComponent from './list';

const UsersComponent = () => {
  return (
    <div>
      <ListComponent />
    </div>
  );
};

export default UsersComponent;
