/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./paidusers.module.scss";
import axios from "axios";
import {
  API_APP_ANNUALUSERLIST,
  API_APP_FREEUSERLIST,
  API_APP_MONTHLYUSERLIST,
  API_ALL_SECURITY_WALLET_AGENT
} from "../../utils/consts/api";
import dummy from "../../assets/images/default-Profile-Pic.jpg";
import { toast } from "react-toastify";

import * as moment from "moment";
import styled from "styled-components";

const ListComponent = ({ token }) => {
  const types = ["Full Security", "Monthly", "Free"];
  const [userList, setuserList] = useState([]);
  const [totalwalletCounts, setTotalwalletCounts] = useState(null);
  const [active, setActive] = useState(types[0]);
  const [usertListBackUp, setuserListBackUp] = useState([]);
  const pageLimit = useRef(5);
  const pageNo = useRef(0);
  const [pageNoMax] = useState(10000000);
  const [cacheNextUserList, setcacheNextUserList] = useState([]);
  const [cachePrevUserList, setcachePrevUserList] = useState([]);
  const [entriesPerPageValue, setEntriesPerPageValue] = useState(5);

  const Tab = styled.button`
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: 0.7;
    background: white;
    border: 0;
    outline: 0;
    color: #9299B8;
    width: 120px;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid #F7B217;
    opacity: 1;
    color: #F7B217;
    font-weight: 500;
  `}
  `;
  const ButtonGroup = styled.div`
    display: flex;
    border-bottom: 1px solid lightgrey;
  `;

  const getNextPrevUsersList = async (whichPage = "next") => {
    whichPage = pageNo.current === 0 ? "next" : whichPage;

    let nextPageNo = pageNo.current;
    if (whichPage === "next") {
      nextPageNo = nextPageNo + 1;
    } else {
      nextPageNo = nextPageNo - 1;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const userListAPIDecider = active === 'Full Security' ? API_APP_ANNUALUSERLIST : active === 'Monthly' ? API_APP_MONTHLYUSERLIST : API_APP_FREEUSERLIST;
      const res = await axios.post(
        userListAPIDecider,
        { page: nextPageNo, size: pageLimit.current, searchString: query },
        { headers }
      );
      if (res.data.responseCode === 0) {
        const data = res.data.response.agentDetails.filter(ele => ele !== null);
        if (whichPage === "next") {
          setcacheNextUserList(
            // res.data.responseCode === 0 ? res.data.response.agentDetails[0] !== null ? res.data.response.agentDetails : [] : []
            data
          );
        } else {
          setcachePrevUserList(
            // res.data.responseCode === 0 ? res.data.response.agentDetails[0] !== null ? res.data.response.agentDetails : [] : []
            data
          );
        }
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getAllUsersList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const userListAPIDecider = active === 'Full Security' ? API_APP_ANNUALUSERLIST : active === 'Monthly' ? API_APP_MONTHLYUSERLIST : API_APP_FREEUSERLIST;
      const res = await axios.post(
        userListAPIDecider,
        { page: pageNo.current, size: pageLimit.current, searchString: query },
        { headers }
      );
      if (res.data.responseCode === 0) {
        const data = res.data.response.agentDetails.filter(ele => ele !== null);
        setuserList(data);
        setuserListBackUp(data);
        if (data.length === pageLimit.current && cacheNextUserList.length === 0) {
          getNextPrevUsersList();
        }
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getTotalCount = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const url = API_ALL_SECURITY_WALLET_AGENT;
      const res = await axios.get(
        url,
        { headers }
      );
      if (res.data.responseCode === 0) {
        setTotalwalletCounts(res.data.response);
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const nextCacheSet = () => {
    if (cacheNextUserList.length > 0) {
      setcachePrevUserList(userList);

      setuserList(cacheNextUserList);
      setuserListBackUp(cacheNextUserList);

      setcacheNextUserList([]);
      getNextPrevUsersList("next");
    } else {
      setcacheNextUserList([]);
    }
  };

  const prevCacheSet = () => {
    if (cachePrevUserList.length > 0) {
      setcacheNextUserList(userList);
      //   this.cacheNextUserList = this.apiAllUsers;
      setuserList(cachePrevUserList);
      setuserListBackUp(cachePrevUserList);
      setcachePrevUserList([]);
      getNextPrevUsersList("prev");
    } else {
      setcachePrevUserList([]);
      //   getAllUsersList();
    }
  };

  useEffect(() => {
    getAllUsersList();
  }, [active]);

  useEffect(() => {
    getTotalCount();
  }, []);

  const resetCache = () => {
    pageNo.current = 0;
    setcacheNextUserList([]);
    setcachePrevUserList([]);
  };

  const nextPrev = (val) => {
    const page = pageNo.current + 1;

    if (val === "next") {
      if (page < pageNoMax) {
        pageNo.current = pageNo.current + 1;
        nextCacheSet();
      }
    } else {
      if (page > 1) {
        pageNo.current = pageNo.current - 1;
        prevCacheSet();
      }
    }
  };

  const entriesPerPage = (e) => {
    setEntriesPerPageValue(e.target.value);
    pageLimit.current = parseInt(e.target.value, 10);
    resetCache();
    getNextPrevUsersList();
    getAllUsersList();
  };
  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const [query, setQuery] = useState("");

  const handleSubmit = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const userListAPIDecider = active === 'Full Security' ? API_APP_ANNUALUSERLIST : active === 'Monthly' ? API_APP_MONTHLYUSERLIST : API_APP_FREEUSERLIST;
      const res = await axios.post(
        userListAPIDecider,
        { page: 0, size: 5, searchString: query },
        { headers }
      );
      if (res.data.responseCode === 0) {
        const data = res.data.response.agentDetails.filter(ele => ele !== null);
        setuserList(data);
        setuserListBackUp(data);
        if (
          data.length === pageLimit.current &&
          cacheNextUserList.length === 0
        ) {
          getNextPrevUsersList();
        }
      } else {
        toast.error(res.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const searchItems = (e) => {
    setQuery(e.target.value);
    if (e.target.value.length >= 3) {
      handleSubmit();
    } else if (e.target.value.length === 0) {
      resetCache();
      handleSubmit();
    }
  };

  const handleTab = (type) => {
    resetCache();
    setEntriesPerPageValue(5);
    setActive(type);
    pageLimit.current = 5;
    pageNo.current = 0;
  }

  return (
    <>
      <div className="row m-0">
        <div className="col-12">
          <div className="d-flex">
            <h5 className="customTitleName">Security Payment</h5>
            <p className={`ms-3 ${styles.userSearchText}`}>
              {userList.length} users
            </p>
            <div class="col-md-8">
              <div class="d-flex form-inputs">
                <input
                  className={`form-control ${styles.searchBar}`}
                  type="text"
                  onChange={searchItems}
                  placeholder="Search..."
                  value={query}
                />
                <i
                  class="fa fa-search"
                  style={{
                    marginLeft: "-30px",
                    opacity: 0.5,
                    marginTop: "22px",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                ></i>
              </div>
            </div>
          </div>
          {totalwalletCounts && totalwalletCounts !== null && <div className={`d-flex ${styles.totalcountsDiv}`}>
            <div className={`${styles.contentDiv}`}>
              <div>
                <h2>
                  {totalwalletCounts.totalAmount.TotalAmountOfAnnualUsers}
                </h2>
                <h6>
                  Total Full Security Amount
                </h6>
              </div>
            </div>
            <div className={`${styles.contentDiv}`}>
              <div>
                <h2>
                  {totalwalletCounts.totalAmount.TotalAmountOfMonthlyUsers}
                </h2>
                <h6>
                  Total Monthly Amount
                </h6>
              </div>
            </div>
            <div className={`${styles.contentDiv}`}>
              <div>
                <h2>
                  {totalwalletCounts.totalUsers.TotalFreeDepositePaidUsers}
                </h2>
                <h6>
                  Total Free Users
                </h6>
              </div>
            </div>
          </div>}
          <div className={`card ${styles.customCard}`}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 p-0">
                  <ButtonGroup>
                    {types.map((type) => (
                      <Tab
                        key={type}
                        active={active === type}
                        onClick={() => handleTab(type)}
                      >
                        {type}
                      </Tab>
                    ))}
                  </ButtonGroup>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className={`w-100 customPginationDiv ${styles.setMargin}`}>
                      <h6 className="customText">Items per page</h6>
                      <select
                        className="form-select customSelectWidth"
                        name="pageLimit"
                        onChange={entriesPerPage}
                        value={entriesPerPageValue}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <button
                        className="btn customButtons buttonMargin"
                        disabled={pageNo.current === 0}
                        onClick={() => nextPrev("prev")}
                      >
                        <i className="fas fa-angle-left"></i>
                      </button>
                      <button
                        className="btn customButtons"
                        disabled={
                          (pageLimit.current >= userList.length &&
                            cacheNextUserList.length === 0) ||
                          userList.length < 5
                        }
                        onClick={() => nextPrev("next")}
                      >
                        <i className="fas fa-angle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-borderless custumTable">
                      <thead className="tablehead">
                        <tr>
                          <th className="headText">User</th>
                          <th className="headText">Email</th>
                          <th className="headText">Company</th>
                          <th className="headText">User Type</th>
                          <th className="headText">Join Date</th>
                          <th className="headText">Status</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {userList &&
                          userList.length > 0 &&
                          userList.map((ele) => {
                            return (
                              <tr key={ele.agentId}>
                                <td>
                                  <div
                                    className="d-flex w-100 align-items-center"
                                    key={ele.agentId}
                                  >
                                    <div>
                                      <img
                                        src={ele.image}
                                        onError={handleImageErr}
                                        alt=""
                                        width="38"
                                        height="38"
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </div>
                                    <div className="ms-2">
                                      <h6 className="m-0 bodyText">
                                        {ele.fullName}
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td className={styles.emailText}>
                                  {ele.email ? ele.email : "-"}
                                </td>
                                <td className="bodyText">
                                  {ele.companyName ? ele.companyName : "-"}
                                </td>
                                <td className="bodyText">{ele.userType}</td>
                                <td className="bodyText">
                                  {moment(new Date(ele.createTs)).format(
                                    "MMMM D, Y"
                                  )}
                                </td>

                                <td
                                  style={
                                    ele.status === "active"
                                      ? { color: "green" }
                                      : { color: "#FF4D4F" }
                                  }
                                >
                                  {ele.status}
                                </td>
                              </tr>
                            );
                          })}
                        {userList && userList.length === 0 && (
                          <div className="text-center w-100">
                            <h4>No Data Found</h4>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ListComponent.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  token: state.user.token,
});

export default connect(mapStateToProps, {})(ListComponent);
