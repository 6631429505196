import React from 'react';
import styles from "../../../views/users/users.module.scss";
import { convertTextField } from '../demoData';




const UserProfile = (props) => {
    
    

    return (
        <div>
        <div className="d-flex w-100 align-items-center" key={props.data.actualData.crmAgentId}>
            <div>
                <img src={props.data.actualData.image} onError={(e)=>props.context.handleImageErr(e)} alt="" width="38" height="38"
                    style={{ borderRadius: '50%' }} />
            </div>
            <div className="ms-2">
                <h6 className="m-0 bodyText">{convertTextField(props.data.actualData.fullName)}</h6>
                {/* <h6 className="m-0 locationText">Bangalore</h6> */}
            </div>
        </div>
    </div>
    );
}

export default UserProfile;
