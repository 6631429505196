import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import B2CDefaultImage from '../../assets/images/Default-Image-B2C.jpg';

const ViewSupportComponent = ({ show, rowData, onHide }) => {

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };

    const B2CProfileImage = rowData?.agentImage !== null && rowData?.agentImage !== "" ? rowData.agentImage : B2CDefaultImage;


    return (
        <Modal
            size='md'
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    View Customer Issues
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="customModalBody">
                <form autocomplete="off">
                    <div className="row">
                        <div class="col-12 col-md-4 col-lg-4 col-xl-4 mb-2 d-flex justify-content-center align-items-center">

                            <img src={B2CProfileImage} onError={handleImageErr} alt="" width="100" height="100"
                                style={{ borderRadius: '50%' }} />

                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-8 form-group mb-2">
                            <label>Customer Name:</label>
                            <input type="text" class="form-control customloginFieldBorder" name='fullName' value={rowData.fullName} readOnly />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 form-group mb-2">
                            <label>Contact Number:</label>
                            <input type="text" class="form-control customloginFieldBorder" name='contactNumber' value={rowData.contactNumber} readOnly />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 form-group mb-2">
                            <label>Email:</label>
                            <input type="text" class="form-control customloginFieldBorder" name='email' value={rowData.email} readOnly />
                        </div>
                        <div class="col-12 mb-2">
                            <label>Comapny Name:</label>
                            <input type="text" class="form-control customloginFieldBorder" name='companyName' value={rowData.companyName} readOnly />
                        </div>
                        <div class="col-12 form-group  mb-2">
                            <label>Customer Issue:</label>
                            <textarea class="form-control textArea customloginFieldBorder" rows="3" name="customerIssue" value={rowData.customerIssue} readOnly></textarea>
                        </div>

                        <div class="col-12 form-group mb-2">
                            <label>Admin Response:</label>
                            <textarea class="form-control textArea customloginFieldBorder" rows="3" name="adminResponse" value={rowData.adminResponse} readOnly></textarea>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={onHide}>Cancel</button>
            </Modal.Footer>
        </Modal>
    );
};

ViewSupportComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    rowData: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    rowData: props.rowData
});

export default connect(mapStateToProps, null)(ViewSupportComponent);