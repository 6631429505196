import React from 'react';
import BookingListComp from './bookingList';

const AllBookingPage = () => {
  return (
    <div>
      <BookingListComp />
    </div>
  );
};

export default AllBookingPage;