/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './exportData.module.scss';
import axios from 'axios';
import { API_EXPORT_LIST, API_GENERATE_REPORT_VEHICLE } from '../../utils/consts/api';
import { toast } from 'react-toastify';
import AgTable from '../../components/Table/AgTable';
import { ExportDataFields } from '../../components/Table/demoData';
import SkeletonLoader from '../../components/Loader';
import PinDialogue from '../../components/PinDialogue';

const ExportDataComponent = ({ token }) => {
    const [spinner,setSpinner]=useState(false);
    const [suggestionList, setsuggestionList] = useState([]);
    const [supportListBackUp, setsuggestionListBackUp] = useState([]);
    const pageLimit = useRef(5);
    // const [pageNo, setpageNo] = useState(0);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(10000000);
    const [isexportData, setExportData] = useState([]);
    const [isAllSelected, setAllSelected] = useState(false);
    const [ischeckedVehicle, setcheckedVehicle] = useState(false);
    const [cacheNextUserList, setcacheNextUserList] = useState([]);
    const [cachePrevUserList, setcachePrevUserList] = useState([]);
    let exportId = useRef(1);

    const getNextPrevSuggestionList = async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_EXPORT_LIST, { page: nextPageNo, size: pageLimit.current }, { headers });
            if (res.data.responseCode === 0) {

                if (res.data.response.agentDetails.length > 0) {
                    res.data.response.agentDetails.forEach(item => {
                        if (isAllSelected) {
                            item.isChecked = true;
                        } else {
                            item.isChecked = false;
                        }


                    });
                }
                if (whichPage === 'next') {
                    setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                } else {
                    setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    

    const getAllSuggestionList = async () => {
        setSpinner(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_EXPORT_LIST,
                //  { page: pageNo.current, 
                //     size: 2000
                //  },
                {},
                  { headers });
            if (res.data.responseCode === 0) {

                if (res.data.response.agentDetails.length > 0) {
                    res.data.response.agentDetails.forEach(item => {
                        if (isAllSelected) {
                            item.isChecked = true;
                        } else {
                            item.isChecked = false;
                        }

                    });
                }

                setsuggestionList(res.data.response.agentDetails);
                // setExportData(res.data.response.agentDetails.vehicleList);
                setsuggestionListBackUp(res.data.response.agentDetails);

                setSpinner(false);

                if (res.data.response.agentDetails.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevSuggestionList();
                }

            } else {

                setSpinner(false);

                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {

            setSpinner(false);

            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const nextCacheSet = () => {
        if (cacheNextUserList.length > 0) {
            setcachePrevUserList(suggestionList)
            //   this.cachePrevUserList = this.apiAllUsers;

            setsuggestionList(cacheNextUserList);
            setsuggestionListBackUp(cacheNextUserList);

            setcacheNextUserList([]);
            getNextPrevSuggestionList('next');
        } else {
            setcacheNextUserList([]);
            getAllSuggestionList();
        }
    }

    const prevCacheSet = () => {
        if (cachePrevUserList.length > 0) {
            setcacheNextUserList(suggestionList);
            //   this.cacheNextUserList = this.apiAllUsers;
            setsuggestionList(cachePrevUserList);
            setsuggestionListBackUp(cachePrevUserList);
            setcachePrevUserList([]);
            getNextPrevSuggestionList('prev');
        } else {
            setcachePrevUserList([]);
            getAllSuggestionList();
        }
    }


    let i = 0;
    useEffect(() => {
        if (i === 0 && cacheNextUserList.length === 0) {
            getAllSuggestionList();
            i++;
        }
    }, [cacheNextUserList]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextUserList([]);
        setcachePrevUserList([]);
        i = 0;
    }

    useEffect(() => {
        const totalArrayList = suggestionList.length;
        const totalCheckedList = suggestionList.filter(item => item.isChecked);
        if (totalArrayList === totalCheckedList.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [suggestionList]);

    const nextPrev = (val) => {

        const page = pageNo.current + 1;

        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }

    const entriesPerPage = (e) => {

        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
    }

    // const exportDataArray = useRef([]);

    // const selectedRow = (event, ele) => {
    //     if (event.target.checked === true) {
    //         exportDataArray.current.push(ele);
    //         const copyArray = [...suggestionList];
    //         const findIndexArray = copyArray.findIndex((data) => data.agentVehicleId === ele.agentVehicleId);
    //         copyArray[findIndexArray] = {
    //             ...copyArray[findIndexArray],
    //             isChecked: true
    //         }
    //         setsuggestionList(copyArray);

    //     } else {
    //         const findIndexArray = exportDataArray.current.findIndex((data) => data.id === ele.agentVehicleId);
    //         exportDataArray.current.splice(findIndexArray, 1);
    //         const copyArray = [...suggestionList];

    //         const findIndexArrayunChecked = copyArray.findIndex((data) => data.agentVehicleId === ele.agentVehicleId);
    //         copyArray[findIndexArrayunChecked] = {
    //             ...copyArray[findIndexArrayunChecked],
    //             isChecked: false
    //         }
    //         setsuggestionList(copyArray);
    //     }
    // }

    // const toggleAllSelected = (event) => {
    //     if (event.target.checked === true) {
    //         const allSelectedArray = suggestionList.map((item) => {
    //             return {
    //                 ...item,
    //                 isChecked: true,
    //             }
    //         });
    //         setsuggestionList(allSelectedArray);
    //         setAllSelected(true);
    //     } else {
    //         const allSelectedArray = suggestionList.map((item) => {
    //             return {
    //                 ...item,
    //                 isChecked: false,
    //             }
    //         });
    //         setsuggestionList(allSelectedArray);
    //         setAllSelected(false);
    //     }
    // };

    // const download = (filename, text) => {
    //     const element = document.createElement('a');
    //     element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    //     element.setAttribute('download', filename);

    //     element.style.display = 'none';
    //     document.body.appendChild(element);
    //     element.click();
    //     document.body.removeChild(element);
    // }

    const [showLoader, setShowLoader] = useState(false);
    const saveAsExcel = async (pin) => {
        // let excelData = [...exportDataArray.current];
        // if (isAllSelected) {
        //     excelData = [...suggestionList]
        // }
        // const tempCsvData = [];
        // excelData.map(es => {
        //     const myobj = {
        //         Name: es.fullName,
        //         'Vehicle Number': es.registrationNumber,
        //         'Vehicle Type': es.vehicleTypeName,
        //         'Phone Number': es.mobileNumber.toString(),
        //         'User Type': es.userType,
        //         'Created At': es.createTs ? moment(new Date(es.createTs)).format('MMMM D, Y') : '-'
        //     };
        //     tempCsvData.push(myobj);
        // });
        // const json = tempCsvData;
        // const fields = Object.keys(json[0]);
        // const replacer = (key, value) => value === null ? '' : value;
        // const csv = json.map((row1) => {
        //     return fields.map((fieldName) => {
        //         return JSON.stringify(row1[fieldName], replacer);
        //     }).join(',');
        // });
        // csv.unshift(fields.join(',')); // add header column
        // const f = csv.join('\r\n');

        // download(`Agent-List${new Date().getTime()}.csv`, f);
        setShowLoader(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const body={
                pin :pin
            }
            const res = await axios.post(API_GENERATE_REPORT_VEHICLE,
                 body ,
                { headers });
            if (res.data.responseCode === 0 && res.data.response.downloadUrl) {
                window.open(res.data.response.downloadUrl, '_self');
                setShowLoader(false);
            } else {
                setShowLoader(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setShowLoader(false);
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    return (

     <div className={styles.exportContainer} >
            {/* <div className="col-12">
                <h5 className={styles.exportData}>Export Data</h5>
                <div className={`card ${styles.customCard}`}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <button className={`btn customButtonActive ${styles.customDisabledBtn}`} style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={saveAsExcel} disabled={showLoader}>Export Data
                                    {showLoader ? <div className={`buttonLoader`}></div> : null}
                                </button>
                                <div className="w-100 customPginationDiv">
                                    <h6 className="customText">Items per page</h6>
                                    <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                    <button className="btn customButtons" disabled={pageLimit.current >= suggestionList.length && cacheNextUserList.length === 0} onClick={() => nextPrev('next')}>
                                        <i className="fas fa-angle-right"></i></button>
                                </div>

                            </div>
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table id="table-to-xls" className="table table-borderless custumTable">
                                        <thead className="tablehead">
                                            <tr> */}
                                                {/* <input type="checkbox" class="form-check-input" checked={isAllSelected} onChange={toggleAllSelected}
                                                    style={{ boxShadow: 'none', border: '1px solid #bbb', margin: '10px 2px 5px 4px' }} /> */}

                                                {/* <th className="headText">User</th>
                                                <th className="headText">Vehicle Number</th>
                                                <th className="headText">Vehicle Type</th>
                                                <th className="headText">Year Of Manufacture</th>
                                                <th className="headText">Mobile Number</th>
                                                <th className="headText">Position</th>
                                                <th className="headText">Join Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableBody">

                                            {suggestionList && suggestionList.length > 0 && suggestionList.map((ele) => (


                                                <tr key={ele.agentId}> */}

                                                    {/* <td>
                                                        <input type="checkbox" class="form-check-input" name="newCheckBox" id={ele.agentVehicleId}
                                                            value={ele.agentVehicleId} checked={ele.isChecked}
                                                            onChange={($event) => selectedRow($event, ele)} style={{ boxShadow: 'none' }} />
                                                    </td> */}
                                                    {/* <td className="bodyText">{ele.fullName ? ele.fullName : '-'}</td>


                                                    <td className="bodyText">
                                                        {ele.registrationNumber ? <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    {ele.registrationNumber}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <p>{ele.registrationNumber}</p>
                                                        </OverlayTrigger> : <p>-</p>}

                                                    </td>
                                                    <td className="bodyText">{ele.vehicleTypeName ? ele.vehicleTypeName : '-'}</td>
                                                    <td className="bodyText">{ele.yearOfManufacture ? ele.yearOfManufacture : '-'}</td> */}

                                                    {/* <td className="bodyText">{reg.agentVehicleId}</td> */}

                                                    {/* <td className="bodyText">{ele.mobileNumber ? ele.mobileNumber : '-'}</td>
                                                    <td className="bodyText">{ele.userType ? ele.userType : '-'}</td>
                                                    <td className="bodyText">{ele.createTs ? moment(new Date(ele.createTs)).format('MMMM D, Y') : '-'}</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

<div className='main-header'>
<div  className='header'>
        <h1 className="customTitleName">Export Data</h1>
        <PinDialogue token={token} setShowLoader={setShowLoader} downloadFile={saveAsExcel} />
         {/* <button className="btn  customButtonActive " onClick={saveAsExcel} disabled={showLoader}>
         
            {showLoader ? <div className={`buttonLoader`}>Downloading</div> : <div>
            Export Data 
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" fill="currentColor" class="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708"/>
            </svg>
            </div>}
         </button> */}
        </div>
</div>
        {spinner&&suggestionList.length==0?
                    <SkeletonLoader/> :
        
            <AgTable 
            section={"ExportDataRows"}
            rowData={suggestionList}
            colDefs={ExportDataFields}
            />
        }
       


    </div>
    );
};

ExportDataComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(ExportDataComponent);