import React from 'react';
import styles from "../../../views/B2C Payments/payment.module.scss";
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';


const CommentHistoryB2C = (props) => {
    return (
        <div className={`${styles.buttonDiv}`}>   
        <div>
          {
            props.data.actualData?.queryComments !== "null" ? 
            <span
            className="bodyText text-center"
            style={{ marginLeft: "15px"}}
          >
            <OverlayTrigger
              key={"top"}
              placement="left"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Comment
                  </Popover.Header>
                  <Popover.Body>
                    {props.data.actualData?.queryComments}
                  </Popover.Body>
                </Popover>
              }
            >
              <i
                class={`far fa-eye ${styles.actionBtn}`}
              ></i>
            </OverlayTrigger>
            </span>
            :
            ''
          }
          <span>
                <OverlayTrigger
                  key={"bottom"}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                    { props.data.actualData?.queryComments === "null" ? "Click to edit" : "Click to Update"}
                    </Tooltip>
                  }
                >
                  <span className={styles.actionBtn}>
                    <i
                      class="fas fa-edit"
                      onClick={() =>
                        props.context.handleAddEditCommentData(props.data.actualData)
                      }
                    ></i>
                  </span>
                </OverlayTrigger>
          </span>
        </div>
      </div>
    );
}

export default CommentHistoryB2C;
