import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import * as moment from "moment";
import {  AggregateRowFields } from "./demoData"; // Correct path to your B2BFields file
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { ModuleRegistry } from "@ag-grid-community/core";
import  "../../views/B2B Payments/payment.module.scss";


ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    MenuModule,
    SetFilterModule,
  ]);


const AgTable = ({ section, rowData ,colDefs, handleDeleteOpen , handleDeleteclose , handleEditFunc,handleDeleteaccept, handleAddEditCommentData ,handleImageErr ,handleResponceOpenView , handleResponceOpen , handleViewFunc , handleB2CImage , toggleStatus , modalShow }) => {




    const gridRef=useRef(null);
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [5, 10, 20, 50, 100];
    const rowHeight = 50;
    const headerHeight = 60; // Set the desired header height in pixels



    const defaultColDef = useMemo(() => {
        return {
          filter: 'agTextColumnFilter',
          floatingFilter: false, 
          resizable: true,
        textCustomComparator: (filter, value, filterText) => {
            // Remove the currency symbol from the value and filter text for comparison
            const numericValue = parseFloat(value.replace(/[₹,]/g, ''));
            const numericFilterText = parseFloat(filterText.replace(/[₹,]/g, ''));
            return numericValue == numericFilterText;
        },
        }
      }, [])


      


      // overlayNoRowsTemplate for if no data is found .
      const overlayNoRowsTemplate = 
      `<div class="text-center w-100">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
      </svg>
      <h4 style="font-family: 'Roboto Condensed', sans-serif;">No Data Found</h4>
      </div>`;


       // Check if there are no rows to show and display the overlay
    const checkNoRows = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            const rowCount = gridRef.current.api.getDisplayedRowCount();
            if (rowCount === 0) {
                gridRef.current.api.showNoRowsOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, []);

   
    
    const getRowStyle = (params) => {

        return { fontWeight: '500' };
        // if (params.node.rowIndex === 0) {
        //     return { fontWeight: 'bold' };
        // }
        // return null;
    };

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            checkNoRows();
        }
    }, [rowData, checkNoRows]);


    return (
        <>
            <div className="ag-theme-quartz"
                style={{
                    height: '80%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign:"center",
                    fontFamily:'Roboto, sans-serif'

                }}
            >
                <div style={{ width: '100%', height: '100%', textAlign: "center", display: "block", justifyContent: "center", alignItems: "center" }}>
                    <AgGridReact
                        ref={gridRef}
                        rowHeight={rowHeight} // Adjust the row height
                        headerHeight={headerHeight} // Adjust the header height
                        defaultColDef={defaultColDef}
                        rowData={AggregateRowFields(section,rowData)}
                        columnDefs={colDefs} // Reference to B2BFields
                        pagination={true}
                        paginationPageSize={paginationPageSize}
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        context={{modalShow:modalShow,handleDeleteOpen ,handleDeleteclose ,handleDeleteaccept , handleEditFunc , handleAddEditCommentData, handleImageErr, handleResponceOpenView , handleResponceOpen , handleViewFunc , handleB2CImage, toggleStatus}}
                        // floatingFilter={true} // Enable floating filters
                        overlayNoRowsTemplate={overlayNoRowsTemplate}
                        onFilterChanged={checkNoRows}
                        getRowStyle={getRowStyle}
                    />
                </div>
            </div>
        </>
    )
}

export default AgTable;
