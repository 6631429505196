import UserActionTypes from './user.types';

const INITIAL_STATE = {
  isLoggedIn: false,
  isAdmin: null,
  token: null,
  userId: null,
  userEmail: null,
  userContact: null,
  userName: null,
  isError: false,
  message: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: action.payload.response.adminId,
        userEmail: action.payload.response.emailId,
        userContact: action.payload.response.mobileNumber,
        token: action.payload.response.token,
        isError: false
      };
    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        isAdmin: null,
        token: null,
        userId: null,
        userEmail: null,
        userContact: null,
        userName: null,
        isError: true,
        message: action.payload.response.errorMsg
      };
    case UserActionTypes.ADMIN_CONTACT_NUMBER:
      return {
        ...state,
        userContact: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
