import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DeleteuserComponent = ({ token, show, onHide, onAccept }) => {
    const inputRef= useRef(null);

    const handleClose = async () => {
        if(inputRef.current.value  && inputRef.current.value.length>=6){
          const inputValue= inputRef.current.value;
    
        //   setIsDownloading(true);
          try{
            await onAccept(inputValue);
          }catch(err){
            toast.error(err);
          }finally{
            // setIsDownloading(false);
          }
        }else if(!inputRef.current.value){
          toast.error('Field is empty')
        }else if(inputRef.current.value.length < 6 ){
          toast.error('Pin should be atleast 6 digits')
        }
        // setShow(false)
      };

    useEffect(()=>{
        if(inputRef.current){
            inputRef.current.focus();
        }
    },[show])

    return (
        <Modal
            size='md'
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Hold Payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5> Are you sure you want to hold the payment ?</h5>
            <br/>
            <InputGroup size="md">
            <Form.Control 
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            placeholder='Enter Pin'
            type='password'
            ref={inputRef}
            />
      </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={onHide}>No</button>
                <button className='btn customButton acceptButton' onClick={handleClose}>Yes</button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteuserComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept
});

export default connect(mapStateToProps, null)(DeleteuserComponent);