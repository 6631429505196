import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_POPUP_NOTIFICATION } from '../../utils/consts/api';
import styles from "./AppManagement.module.scss";

const PopupNotificationComponent = ({ show, onHide, token }) => {

    const [editInputs, editSetInputs] = useState({
        title: '',
        description: ''
    });

    const { title, description } = editInputs;

    const handleChange = (e) => {
        const { name, value } = e.target;
        editSetInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const hideDialogBox = () => {
        editSetInputs((prevState) => ({
            ...prevState,
            title: '',
            description: ''
        }));
        onHide();
    };

    const onSaveChanged = async (event) => {
        event.preventDefault();
        const obj = {
            "title": title,
            "body": description
        };
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_POPUP_NOTIFICATION, obj, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                hideDialogBox();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.modalOterDiv}
            show={show}
            keyboard={false}
            backdrop="static"
            onHide={hideDialogBox}
        >
            <div className={styles.modalmainDiv}>
                <div className={styles.modalDiv}>
                    <div className={`${styles.modalTitle}`}>
                        Send Popup Notification
                    </div>
                    <div className={styles.closeButtonDiv}>
                        <div
                            className={`${styles.closeButton}`}
                            onClick={hideDialogBox}>
                            x
                        </div>
                    </div>
                </div>
            </div>
            <hr className={styles.borderLine} style={{ marginBottom: "0px" }} />
            <Modal.Body className={`${styles.modalBody}`}>
                <div className={styles.midSection}>
                    <form onSubmit={onSaveChanged}>
                        <div className={styles.addPlanDiv}>
                            <label className={styles.addPlanText}>Tilte</label>
                            <input type="text" id="inputTilte" name='title' className={styles.addPlanInput} value={title} onChange={handleChange} aria-describedby="titleHelp" placeholder="Enter title" required />
                        </div>
                        <div className={styles.addPlanDiv}>
                            <label className={styles.addPlanText}>Description</label>
                            <textarea
                                type="text"
                                style={{ height: "170px" }}
                                className={styles.addPlanInput}
                                required
                                placeholder="Enter here..."
                                name="description"
                                value={description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.buttonDiv}>
                            <button className={styles.uploadButton} type="submit" style={{ padding: '0 15px' }}>
                                Send
                            </button>
                            <button
                                className={styles.cancelButton}
                                type="button"
                                onClick={hideDialogBox}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

PopupNotificationComponent.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
};

const mapStateToProps = (state, props) => ({
    show: props.show,
    onHide: props.onHide,
    token: state.user.token,
});

export default connect(mapStateToProps, null)(PopupNotificationComponent);