import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiPhone } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';
import "./bookingListPage.scss";

const PostedAgent = (props) => {
    

    return (
        <div className="bodyText">
            {props.data.actualData.customerId&& <OverlayTrigger
                    key="right"
                    delay={{ show: 250, hide: 400 }}
                    placement="right"
                    className="testing"
                    overlay={
                        <Tooltip id="tooltip-left">
                            <div>
                                <div className="infoOverlayDiv">
                                    <div>Agent :</div>
                                    <div>
                                        {props.data.actualData.postedMobileNo ? (
                                            <span><BiPhone /> +919258196242</span>
                                        ) : (
                                            '+919258196242'
                                        )}
                                    </div>
                                </div>
                                <div className="infoOverlayDiv">
                                    <div>Customer :</div>
                                    <div>
                                        {props.data.actualData.customerMobileNumber ? (
                                            <span><BiPhone /> {props.data.actualData.customerMobileNumber}</span>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    }
                >
                    <span>Info <MdOutlineInfo /></span>
                </OverlayTrigger>
                ||
                <div>
                <h6>{props.data.actualData.postedAgentName ? props.data.actualData.postedAgentName : 'NA'}</h6>
                <h6  style={{ fontSize: '11.2px' }}><BiPhone /> {props.data.actualData.postedMobileNo ? props.data.actualData.postedMobileNo : '-'}</h6>
                </div>
                }
        </div>
    );
}

export default PostedAgent;
