/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./AppManagement.module.scss";
import axios from "axios";
import {
  API_APP_CONTENT_BANNER_DELETE,
  URL_REGEX,
  API_APP_CONTENT_ADDHOMEBANNER,
  API_APP_CONTENT_GETHOMEBANNER,
  API_APP_CUSTOMNOTIFICATIONSEND,
} from "../../utils/consts/api";
import dummy from "../../assets/images/default-Profile-Pic.jpg";
import { toast } from "react-toastify";
import moment from "moment";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import { Modal } from "react-bootstrap";
import { useImageCompression } from "../../components/ImageCompression/useImageCompression";
import PopupNotificationComponent from "./send-popup-notification";

const AppContentManagementComponent = ({ token }) => {
  const [isValid, setIsValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [bannerImagesData, setbBannerImagesData] = useState([]);
  const [bannerData, setBannerData] = useState({
    image: "bannerImage",
    bannerLink: "",
  });
  const bannerImageIntialValues = {
    raw: "",
    preview: "",
  };
  const [bannerImage, setBannerImage] = useState(bannerImageIntialValues);
  const [show, setShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const { data, imageCompressionfn } = useImageCompression();
  const [customNotificationData, setCustomNotificationData] = useState({
    title: "",
  });

  const handleClose = () => {
    setBannerImage(bannerImageIntialValues);
    setBannerData({
      image: "bannerImage",
      bannerLink: "",
    });
    setShow(false);
  };

  const handleNotificationClose = () => {
    setNotificationShow(false);
  };

  const handleShow = () => setShow(true);

  const handleBannerImage = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      imageCompressionfn(file);
    }
  };

  const handleDelete = (id) => {
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    axios
      .post(API_APP_CONTENT_BANNER_DELETE, { homeBannerId: id }, { headers })
      .then((res) => {
        if (res.data.responseCode === -1) {
          toast.error(res.data.errorMsg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(res.data.successMsg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getData();
        }
      })
      .catch((error) => {
        toast.error("Internal server error.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleBannerImageData = (e) => {
    setBannerData({
      ...bannerData,
      bannerLink: e.target.value,
    });
  };

  const handleBannerImageSubmission = (e) => {
    e.preventDefault();
    if (!bannerData.bannerLink || !isValid) {
      toast.error("Please First Enter URL Link", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const headers = {
      "Content-Type": "multipart/form-data",
      authorization: token,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(bannerData));
    if (bannerImage.raw) {
      formData.append("bannerImage", bannerImage.raw, bannerImage.raw.name);
    } else if (!bannerImage.raw) {
      toast.error("Please upload the banner image", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    axios
      .post(API_APP_CONTENT_ADDHOMEBANNER, formData, { headers })
      .then((res) => {
        if (res.data.responseCode === -1) {
          toast.error(res.data.errorMsg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(res.data.successMsg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        }
        getData();
      })
      .catch((error) => {
        toast.error("Internal server error.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setBannerData({
      image: "bannerImage",
      bannerLink: "",
    });
  };

  const getData = () => {
    axios
      .get(API_APP_CONTENT_GETHOMEBANNER, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then(({ data }) => {
        setbBannerImagesData(data.response.bannerList);
      })
      .catch((error) => {
        toast.error("Internal server error.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleURLValidation = () => {
    if (bannerData.bannerLink) {
      if (!URL_REGEX.test(bannerData.bannerLink)) {
        setIsValid(false);
        setErrorMsg("Invalid URL.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const handleCustomNotification = () => {
    setNotificationShow(true);
  };

  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setCustomNotificationData({ ...customNotificationData, [name]: value });
  };

  const sendNotificationData = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        "Content-Type": "application/json",
        authorization: token,
      };
      const customResult = await axios.post(
        API_APP_CUSTOMNOTIFICATIONSEND,
        customNotificationData,
        { headers }
      );
      if (customResult.data.responseCode === 0) {
        toast.success(customResult.data.successMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleNotificationClose();
      } else {
        toast.error(customResult.data.errorMsg, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Internal server error.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleNotificationClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.image) {
        setBannerImage({
          ...bannerImage,
          preview: URL.createObjectURL(data?.image),
          raw: data?.image,
        });
      }
    }
  }, [data]);

  const [showPopupNotification, setShowPopupNotification] = useState(false);
  const hideAndShowPopupNotificationDialogBox = (data) => {
    if (data === true) {
      setShowPopupNotification(true);
    } else {
      setShowPopupNotification(false);
    }
    
  };

  return (
    <div className="row m-0" style={{ backgroundColor: "#F4F5F7" }}>
      <div className="col-12">
        <div className={styles.customNotificationDiv}>
          <h5 className="customTitleName">App Content Management</h5>
          <div className={styles.buttonDiv}>
            <button
              onClick={handleCustomNotification}
              className={styles.addNewPlan}
            >
              Send Custom Notification
            </button>
            <button
              onClick={() => hideAndShowPopupNotificationDialogBox(true)}
              className={styles.addNewPlan}
            >
              Send Popup Notification
            </button>
          </div>

        </div>
        <div className={`d-flex gap-2 ${styles.mainContainer}`}>
          <div className={styles.bannerCardSlot}>
            {bannerImagesData[0] ? (
              <img className={styles.bannerImage} rc={bannerImagesData[0].bannerImage} height="233px" width="380px" />
            ) : (
              <div className={styles.imageContainer} onClick={handleShow}>
                <FiUpload type="file" className={styles.fiRCUpload} />
              </div>
            )}
            <div className={styles.bannerInformation}>
              <div>
                <h4 className={styles.bannnerText}>First Banner</h4>
                <p className={styles.uploadImageAndLink}>
                  {bannerImagesData[0]
                    ? `Updated On ${moment(
                      bannerImagesData[0].updateTs.slice(0, 16)
                    ).format("DD MMM YYYY h:mm A")}`
                    : "Upload Image & Link"}
                </p>
              </div>
              {bannerImagesData[0] && (
                <RiDeleteBinLine
                  style={{ color: "red" }}
                  onClick={() => handleDelete(bannerImagesData[0].homebannerId)}
                />
              )}
            </div>
          </div>
          <div className={styles.bannerCardSlot}>
            {bannerImagesData[1] ? (
              <img
                className={styles.bannerImage}
                src={bannerImagesData[1].bannerImage}
              />
            ) : (
              <div className={styles.imageContainer} onClick={handleShow}>
                <FiUpload type="file" className={styles.fiRCUpload} />
              </div>
            )}
            <div className={styles.bannerInformation}>
              <div>
                <h4 className={styles.bannnerText}>Second Banner</h4>
                <p className={styles.uploadImageAndLink}>
                  {bannerImagesData[1]
                    ? `Updated On ${moment(
                      bannerImagesData[1].updateTs.slice(0, 16)
                    ).format("DD MMM YYYY h:mm A")}`
                    : "Upload Image & Link"}
                </p>
              </div>
              {bannerImagesData[1] && (
                <RiDeleteBinLine
                  style={{ color: "red" }}
                  onClick={() => handleDelete(bannerImagesData[1].homebannerId)}
                />
              )}
            </div>
          </div>
          <div className={styles.bannerCardSlot}>
            {bannerImagesData[2] ? (
              <img
                className={styles.bannerImage}
                src={bannerImagesData[2].bannerImage}
              />
            ) : (
              <div className={styles.imageContainer} onClick={handleShow}>
                <FiUpload type="file" className={styles.fiRCUpload} />
              </div>
            )}
            <div className={styles.bannerInformation}>
              <div>
                <h4 className={styles.bannnerText}>Third Banner</h4>
                <p className={styles.uploadImageAndLink}>
                  {bannerImagesData[2]
                    ? `Updated On ${moment(
                      bannerImagesData[2].updateTs.slice(0, 16)
                    ).format("DD MMM YYYY h:mm A")}`
                    : "Upload Image & Link"}
                </p>
              </div>
              {bannerImagesData[2] && (
                <RiDeleteBinLine
                  style={{ color: "red" }}
                  onClick={() => handleDelete(bannerImagesData[2].homebannerId)}
                />
              )}
            </div>
          </div>
          <div className={styles.bannerCardSlot}>
            {bannerImagesData[3] ? (
              <img
                className={styles.bannerImage}
                src={bannerImagesData[3].bannerImage}
              />
            ) : (
              <div className={styles.imageContainer} onClick={handleShow}>
                <FiUpload type="file" className={styles.fiRCUpload} />
              </div>
            )}
            <div className={styles.bannerInformation}>
              <div>
                <h4 className={styles.bannnerText}>Fourth Banner</h4>
                <p className={styles.uploadImageAndLink}>
                  {bannerImagesData[3]
                    ? `Updated On ${moment(
                      bannerImagesData[3].updateTs.slice(0, 16)
                    ).format("DD MMM YYYY h:mm A")}`
                    : "Upload Image & Link"}
                </p>
              </div>
              {bannerImagesData[3] && (
                <RiDeleteBinLine
                  style={{ color: "red" }}
                  onClick={() => handleDelete(bannerImagesData[3].homebannerId)}
                />
              )}
            </div>
          </div>
          <div className={styles.bannerCardSlot}>
            {bannerImagesData[4] ? (
              <img
                className={styles.bannerImage}
                src={bannerImagesData[4].bannerImage}
              />
            ) : (
              <div className={styles.imageContainer} onClick={handleShow}>
                <FiUpload type="file" className={styles.fiRCUpload} />
              </div>
            )}
            <div className={styles.bannerInformation}>
              <div>
                <h4 className={styles.bannnerText}>Fifth Banner</h4>
                <p className={styles.uploadImageAndLink}>
                  {bannerImagesData[4]
                    ? `Updated On ${moment(
                      bannerImagesData[4].updateTs.slice(0, 16)
                    ).format("DD MMM YYYY h:mm A")}`
                    : "Upload Image & Link"}
                </p>
              </div>
              {bannerImagesData[4] && (
                <RiDeleteBinLine
                  style={{ color: "red" }}
                  onClick={() => handleDelete(bannerImagesData[4].homebannerId)}
                />
              )}
            </div>
          </div>
        </div>

        {/* Upload Image Modal Here */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={styles.modalOterDiv}
          show={show}
          onHide={handleClose}
        >
          <div className={styles.modalmainDiv}>
            <div className={styles.modalDiv}>
              <div className={`${styles.modalTitle}`}>Upload Banner</div>
              <div className={styles.closeButtonDiv}>
                <div className={`${styles.closeButton}`} onClick={handleClose}>
                  x
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.borderLine} />
          <Modal.Body className={`${styles.modalBody}`}>
            <div className={styles.midSection}>
              <div className={styles.uploadSection}>
                <p className={styles.bannerText}>Paste Link for the Banner</p>
                <div className={styles.inputSection}>
                  {bannerImage.preview ? (
                    <img
                      src={bannerImage.preview}
                      className={styles.bannerImagePreview}
                    />
                  ) : (
                    <div>
                      <label
                        htmlFor="file-bannerImage"
                        className={`form-label ${styles.bannerLabel}`}
                      >
                        Click Here{" "}
                      </label>
                      <span className={styles.uploadText}>
                        to upload banner image
                      </span>
                      <input
                        type="file"
                        id="file-bannerImage"
                        className="d-none"
                        accept="image/*"
                        placeholder="RC Front"
                        onChange={handleBannerImage}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.bannerLinkDiv}>
                <p className={styles.bannerText}>Paste Link for Banner</p>
                <input
                  className={styles.bannerInput}
                  type="text"
                  placeholder="Place Link Here"
                  onChange={handleBannerImageData}
                  onBlur={handleURLValidation}
                />
                {!isValid && <div className="text-danger">{errorMsg}</div>}
              </div>
              <div className={styles.buttonDiv}>
                <button
                  className={styles.uploadButton}
                  onClick={handleBannerImageSubmission}
                >
                  Upload
                </button>
                <button className={styles.cancelButton} onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Send custom notification modal starts here */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={styles.modalOterDiv}
          show={notificationShow}
          onHide={handleNotificationClose}
        >
          <div className={styles.modalmainDiv}>
            <div className={styles.modalDiv}>
              <div className={`${styles.modalTitle}`}>
                Send Custom Notification
              </div>
              <div className={styles.closeButtonDiv}>
                <div
                  className={`${styles.closeButton}`}
                  onClick={handleNotificationClose}
                >
                  x
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.borderLine} style={{ marginBottom: "0px" }} />
          <Modal.Body className={`${styles.modalBody}`}>
            <div className={styles.midSection}>
              <form onSubmit={sendNotificationData}>
                <div className={styles.addPlanDiv}>
                  <label className={styles.addPlanText}>Description</label>
                  <textarea
                    type="text"
                    style={{ height: "170px" }}
                    className={styles.addPlanInput}
                    required
                    placeholder="Enter here..."
                    name="title"
                    onChange={handleNotificationChange}
                  />
                </div>
                <div className={styles.buttonDiv}>
                  <button className={styles.uploadButton} type="submit">
                    Send Notification
                  </button>
                  <button
                    className={styles.cancelButton}
                    type="button"
                    onClick={handleNotificationClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        {/* Send custom notification ends here */}

        {/* send popup notification */}
        <PopupNotificationComponent
          show={showPopupNotification}
          onHide={() => hideAndShowPopupNotificationDialogBox(false)}
        />
        {/* ================= */}
      </div>
    </div>
  );
};

AppContentManagementComponent.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  token: state.user.token,
});

export default connect(mapStateToProps, null)(AppContentManagementComponent);
