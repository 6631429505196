import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_CRM_ADDCUSTOMER, API_CRM_UPDATECUSTOMER, API_EDIT_DRIVER } from '../../utils/consts/api';
import style from './users.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import flagImg from '../../assets/images/ProfileImage.png';
import { useImageCompression } from '../../components/ImageCompression/useImageCompression';

const AddEditCustomerComponent = ({ type, show, onHide, onAccept, token, rowData, userDetal }) => {
    const [customerImageFile, setCustomerImageFile] = useState('');
    const [cutomerImageUrl, setCustomerImageUrl] = useState('');

    const [editInputs, editSetInputs] = useState({
        customerName: '',
        contactNumber: '',
        emailId: '',
        location: ''
    });

    const { customerName, contactNumber, emailId, location } = editInputs;
    const {data,imageCompressionfn} = useImageCompression();

    const handleChange = (e) => {
        const { name, value } = e.target;
        editSetInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    let i = 0;
    useEffect(() => {
        if (i === 0) {
            editSetInputs({
                customerName: type === 'editCustomer' ? rowData.customerName : '',
                contactNumber: type === 'editCustomer' ? rowData.contactNumber : '',
                emailId: type === 'editCustomer' ? rowData.emailId : '',
                location: type === 'editCustomer' ? rowData.location : ''
            });
            setCustomerImageUrl(type === 'editCustomer' ? rowData.image : '');
            i++;
        }
    }, [type]);


    const handleImageErr = (e) => {
        e.target.src = flagImg;
    };

    const initialFileCustomerUpload = (event) => {
        event.preventDefault();
        const element = document.getElementById('customerPicInput');
        element.click();
    };

    const onCustomerFileChanged = async (event) => {
        const fileData = event.target.files[0];
        
        if(fileData){
            imageCompressionfn(fileData)
         }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setCustomerImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    };

    const acceptDialogBox = () => {
        onAccept();
        editSetInputs((prevState) => ({
            ...prevState,
            customerName: '',
            contactNumber: '',
            emailId:'',
            location:''
        }));

        setCustomerImageFile('');
        setCustomerImageUrl('');
    };

    const hideDialogBox = () => {
        onHide();
        editSetInputs((prevState) => ({
            ...prevState,
            customerName: '',
            contactNumber: '',
            emailId: '',
            location: ''
        }));

        setCustomerImageFile('');
        setCustomerImageUrl('');
    };

    const onSaveChanged = async (event) => {

        const obj = {
            agentId: type === 'editCustomer' ? rowData.crmAgentId : userDetal.agentId,
            customerName: customerName,
            contactNumber: contactNumber,
            emailId: emailId,
            location: location,
            image: "customerImage",
        };

        if (type === 'editCustomer') {
            obj.customerId = rowData.customerId;
        }

        const formDataValue = new FormData();

        formDataValue.append('data', JSON.stringify(obj));
        
        if (customerImageFile) {
            formDataValue.append('customerImage', customerImageFile, customerImageFile.name);
        } else {
            formDataValue.append('customerImage', '');
        }

        if (type === 'editCustomer') {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_CRM_UPDATECUSTOMER, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_CRM_ADDCUSTOMER, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    useEffect(()=>{
       if(data){
        setCustomerImageFile(data?.image);
       }
    },[data]);

    return (
        <Modal
            size='md'
            show={show}
            onHide={hideDialogBox}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'editCustomer' ? 'Edit Customer' : 'Add Customer'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="customModalBody">
                <form autoComplete="off">
                    <div className="row">
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputname1">Name</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputname1" name='customerName' value={customerName} onChange={handleChange} aria-describedby="nameHelp" placeholder="Enter name" />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputnumber1">Phone Number</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputnumber1" maxLength={10} name='contactNumber' value={contactNumber} aria-describedby="numberHelp" placeholder="Enter phone number" onChange={handleChange} onKeyDown={validateNum} />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputEmail1">Customer Email</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputEmail1" name='emailId' value={emailId} aria-describedby="emailHelp" placeholder="Enter your email" onChange={handleChange} />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputLocation1">Location</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputLocation1" name='location' value={location} aria-describedby="emailHelp" placeholder="Enter your location" onChange={handleChange} />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Customer Image</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!cutomerImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!cutomerImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileCustomerUpload($event)}></i>}
                                            {cutomerImageUrl && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileCustomerUpload($event)}></i>}
                                            <input type="file" id="customerPicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onCustomerFileChanged($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {cutomerImageUrl && <div className="card-body text-center">
                                    <img src={cutomerImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={hideDialogBox}>Cancel</button>
                <button className='btn customButton acceptButton' onClick={onSaveChanged}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

AddEditCustomerComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any,
    rowData: PropTypes.any,
    userDetal: PropTypes.any,
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept,
    rowData: props.rowData,
    userDetal: state.userMenu.userDetails,
});

export default connect(mapStateToProps, null)(AddEditCustomerComponent);