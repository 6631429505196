import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userSignInStart } from '../../redux/user/user.actions';
import styles from './auth.module.scss';
import bgImage from '../../assets/images/login_bg_image.png';
import { EMAIL_REGEX } from '../../utils/consts/api';


const LoginComponent = ({ userSignInStart, userEmail }) => {

    const [inputs, setInputs] = useState({
        email: userEmail,
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const { email, password } = inputs;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (!value) {
            setIsValid(true);
            setErrorMsg('')
        }
    };

    const validateEmail = () => {
        if (email) {
            if (!EMAIL_REGEX.test(email)) {
                setIsValid(false);
                setErrorMsg('Invalid Email Id.')
            } else {
                setIsValid(true);
                setErrorMsg('')
            }
        } else {
            setIsValid(true);
            setErrorMsg('')
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (email && password) {
            userSignInStart(inputs);
        }
    };


    const [showPassword, setshowPassword] = useState(false);

    const handlePassword = () => {
        setshowPassword(!showPassword);
    }

    return (
        <div className={styles.authcomponent}>
            <div className={`d-none d-md-block ${styles.bgleftimage}`} style={{ backgroundImage: `url(${bgImage})` }}>
            </div>
            <div className={styles.bgrightloginform}>
                <div className={styles.formBox}>
                    <h4 className='mb-4'>Sign in to Admin</h4>
                    <form autocomplete="off">
                        <div class="form-group mb-3">
                            <label htmlFor="exampleInputEmail1">Username or Email address</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputEmail1" name='email' value={email} onChange={handleChange}
                                onBlur={validateEmail} aria-describedby="emailHelp" placeholder="Enter email" />
                            {submitted && !email && (
                                <div className='invalid-feedback'>
                                    Email is required
                                </div>
                            )}
                            {!isValid && <div className="text-danger">{errorMsg}</div>}
                        </div>
                        <div class="form-group mb-3">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <div class="input-group customloginFieldBorder">
                                <input type={showPassword ? 'text' : 'password'} class="form-control" id="exampleInputPassword1" name='password' value={password} onChange={handleChange} placeholder="Password" />
                                {!showPassword && <span class={`input-group-text ${styles.eyeStyle}`} >
                                    <i class="far fa-eye-slash" onClick={handlePassword}></i>
                                </span>}
                                {showPassword && <span class={`input-group-text ${styles.eyeStyle}`} >
                                    <i class="far fa-eye" onClick={handlePassword}></i>
                                </span>}
                            </div>

                            {submitted && !password && (
                                <div className='invalid-feedback'>
                                    Password is required
                                </div>
                            )}

                        </div>
                        <div class="form-group mb-4" className={styles.keepLogged}>
                            <div className="form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                <label class="form-check-label" for="exampleCheck1">Keep me logged in</label>
                            </div>
                            <div>
                                <Link to="/forget-password" style={{ color: '#272b41' }}>Forgot Password ?</Link>
                            </div>
                        </div>
                        <button type="submit" class="btn customButtonActive" disabled={!email || !password || !isValid}
                            onClick={handleSubmit}>Sign In</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

LoginComponent.propTypes = {
    userSignInStart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userEmail: state.user.userEmail
});

export default connect(mapStateToProps, { userSignInStart })(LoginComponent);
