import React from 'react';
import styles from './dashboardlayout.module.scss';
import { Link } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { Dropdown } from 'react-bootstrap';
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavItem,
  NavLink,
} from "reactstrap";
// import { IoNotificationsOutline, IoMailOutline, IoSettingsOutline, IoHelpCircleOutline, IoSearchOutline } from 'react-icons/io5'
// import flagImg from '../../assets/images/flag.png';
import logoImg from '../../assets/images/logo.png';

const TopBar = () => {
  const handleSignout = () => {
    localStorage.clear();
    window.location.href = '/login'
    // windows.locanavigate('/login');
  }

  return (
    <div>
      <Navbar light expand="md">
        {/* <BiMenuAltLeft/> */}
        <div className={styles.navSearch}>
          <NavbarBrand href="/"><img src={logoImg} alt='' /></NavbarBrand>
          {/* <input type='text' placeholder='Search'></input>
          <IoSearchOutline /> */}
        </div>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/components/"></NavLink>
          </NavItem>
        </Nav>
        <NavbarText className={styles.navbarText}>
          {/* <div>
            <IoMailOutline />
          </div>
          <div>
            <IoNotificationsOutline />
          </div>
          <div>
            <IoSettingsOutline />
          </div>
          <div>
            <IoHelpCircleOutline />
          </div>
          <div>
            <img src={flagImg} alt='' />
          </div> */}
          {/* <div>
            <AiOutlineUser></AiOutlineUser>
          </div> */}
          <div className='dropdownbtn'>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <AiOutlineUser></AiOutlineUser>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
              <Link to="/app/adminProfile" style={{display: 'block' }}>My Profile</Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleSignout}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </div>
        </NavbarText>
      </Navbar>
    </div>
  );
};

export default TopBar;
