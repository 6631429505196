import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import style from './users.module.scss';
import { useNavigate } from 'react-router-dom';
import { API_EDIT_VEHICLE, API_ADD_VEHICLE, API_VEHICLE_LIST } from '../../utils/consts/api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import flagImg from '../../assets/images/ProfileImage.png';
import { useImageCompression } from '../../components/ImageCompression/useImageCompression';
import * as moment from 'moment';

const AddEditVehicleComponent = ({ type, show, onHide, onAccept, token, rowData, userDetal }) => {
    const [insImageFileFile, setInsImageFile] = useState('');
    const [vehicleImageFileFile, setvehicleImageFile] = useState('');
    const [vehicleImageFileFile1, setvehicleImageFile1] = useState('');
    const [vehicleImageFileFile2, setvehicleImageFile2] = useState('');
    const [vehicleImageFileFile3, setvehicleImageFile3] = useState('');
    const [vehicleImageFileFile4, setvehicleImageFile4] = useState('');
    const [vehicleRcImageFile, setvehicleLicenseFile] = useState('');
    const [vehicleRcImageFile2, setvehicleLicenseFile2] = useState('');
    const [vehicleTypeList, setvehicleTypeList] = useState([]);

    const [insImageUrl, setInsImageUrl] = useState('');
    const [vehicleImageUrl, setvehicleImageUrl] = useState('');
    const [vehicleImageUrl2, setvehicleImageUrl2] = useState('');
    const [vehicleImageUrl3, setvehicleImageUrl3] = useState('');

    const [vehicleImageUrl4, setvehicleImageUrl4] = useState('');
    const [vehicleImageUrl5, setvehicleImageUrl5] = useState('');
    const [vehicleRcImageUrl, setvehicleRcImageUrl] = useState('');
    const [vehicleRcImageUrl2, setvehicleRcImageUrl2] = useState('');
    const handleImageErr = (e) => {
        e.target.src = flagImg;
    };
    const [editInputs, editSetInputs] = useState({
        registrationNumber: '',
        yearOfManufacture: '',
        vehicleTypeId: '',
        updatedExpiryDate:''
    });

    const { registrationNumber, yearOfManufacture, vehicleTypeId, updatedExpiryDate } = editInputs;
    const { data, imageCompressionfn } = useImageCompression();


    const handleChange = (e) => {

        const { name, value } = e.target;
        editSetInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }


    const getAllVehicleList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_VEHICLE_LIST, {}, { headers });
            if (res.data.responseCode === 0) {
                setvehicleTypeList(res.data.response.vehicleTypes);
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    let i = 0;
    useEffect(() => {
        if (i === 0) {
            getAllVehicleList();
            editSetInputs({
                registrationNumber: type === 'editVehicle' ? rowData.registrationNumber : '',
                yearOfManufacture: type === 'editVehicle' ? rowData.yearOfManufacture : '',
                vehicleTypeId: type === 'editVehicle' ? rowData.vehicleTypeId : '',
                updatedExpiryDate: type === 'editVehicle' ? moment(rowData?.updatedExpiryDate).format("YYYY-MM-DD") : '',
            });
            setInsImageUrl(type === 'editVehicle' ? rowData.insuranceImage ? rowData.insuranceImage : '' : '')
            setvehicleImageUrl(type === 'editVehicle' ? rowData.vehicleImage : '');
            setvehicleImageUrl2(type === 'editVehicle' ? rowData.vehicleImage2 : '');
            setvehicleImageUrl3(type === 'editVehicle' ? rowData.vehicleImage3 : '');

            setvehicleImageUrl4(type === 'editVehicle' ? rowData.vehicleImage4 : '');
            setvehicleImageUrl5(type === 'editVehicle' ? rowData.vehicleImage5 : '');

            setvehicleRcImageUrl(type === 'editVehicle' ? rowData.rcImage : '');
            setvehicleRcImageUrl2(type === 'editVehicle' ? rowData.rcImageBack : '');
            i++;
        }
    }, [type]);

    const initialFileInsUpload = (event) => {
        event.preventDefault();
        const element = document.getElementById('insPicInput');
        element.click();
    };
    const onInsFileChanged = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'insImage');
        }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setInsImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }

    const initialFileVehicleUpload = (event) => {
        event.preventDefault();
        const element = document.getElementById('vehiclePicInput');
        element.click();
    };
    const onVehicleFileChanged = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'vehicleImage');
        }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const initialFileVehicleUpload1 = (event) => {
        event.preventDefault();
        const element = document.getElementById('vehiclePicInput1');
        element.click();
    };
    const onVehicleFileChanged1 = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'vehicleImage1');
        }

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleImageUrl2(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const initialFileVehicleUpload2 = (event) => {
        event.preventDefault();
        const element = document.getElementById('vehiclePicInput2');
        element.click();
    };
    const onVehicleFileChanged2 = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'vehicleImage2');
        };


        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleImageUrl3(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const initialFileVehicleUpload3 = (event) => {
        event.preventDefault();
        const element = document.getElementById('vehiclePicInput3');
        element.click();
    };
    const onVehicleFileChanged3 = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'vehicleImage3');
        };

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleImageUrl4(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const initialFileVehicleUpload4 = (event) => {
        event.preventDefault();
        const element = document.getElementById('vehiclePicInput4');
        element.click();
    };
    const onVehicleFileChanged4 = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'vehicleImage4');
        };

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleImageUrl5(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const initialFileRCUpload = (event) => {
        event.preventDefault();
        const element = document.getElementById('rcPicInput');
        element.click();
    };
    const initialFileRCUpload2 = (event) => {
        event.preventDefault();
        const element = document.getElementById('rcPicInput2');
        element.click();
    };

    const onLicenseFileChanged = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'lisenceFrontImage');
        };

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleRcImageUrl(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const onLicenseFileChanged2 = async (event) => {
        const fileData = event.target.files[0];

        if (fileData) {
            imageCompressionfn(fileData, 'lisenceBackImage');
        };

        if (fileData) {
            const reader = new FileReader();

            reader.onload = (event) => {

                setvehicleRcImageUrl2(reader.result);
            };

            reader.readAsDataURL(fileData);
        }
    }
    const hideDialogBox = () => {
        onHide();
        editSetInputs((prevState) => ({
            ...prevState,
            registrationNumber: '',
            yearOfManufacture: '',
            updatedExpiryDate: ''
        }));
        setInsImageFile('');
        setvehicleImageFile('');
        setvehicleImageFile1('');
        setvehicleImageFile2('');
        setvehicleImageFile3('');
        setvehicleImageFile4('');
        setvehicleLicenseFile('');
        setvehicleLicenseFile2('');
        setInsImageUrl('');
        setvehicleImageUrl('');
        setvehicleImageUrl2('');
        setvehicleImageUrl3('');
        setvehicleImageUrl4('');
        setvehicleImageUrl5('');
        setvehicleRcImageUrl('');
        setvehicleRcImageUrl2('');
    }
    const acceptDialogBox = () => {
        onAccept();
        editSetInputs((prevState) => ({
            ...prevState,
            registrationNumber: '',
            yearOfManufacture: '',
            updatedExpiryDate: ''
        }));
        setInsImageFile('');
        setvehicleImageFile('');
        setvehicleImageFile1('');
        setvehicleImageFile2('');
        setvehicleImageFile3('');
        setvehicleImageFile4('');
        setvehicleLicenseFile('');
        setvehicleLicenseFile2('');
        setInsImageUrl('');
        setvehicleImageUrl('');
        setvehicleImageUrl2('');
        setvehicleImageUrl3('');
        setvehicleImageUrl4('');
        setvehicleImageUrl5('');
        setvehicleRcImageUrl('');
        setvehicleRcImageUrl2('');
    }

    const onSaveChanged = async (event) => {

        const obj = {
            agentId: type === 'editVehicle' ? rowData.agentId : userDetal.agentId,
            vehicleTypeId: vehicleTypeId ? vehicleTypeId : vehicleTypeList[0].vehicleTypeId,
            registrationNumber: registrationNumber,
            yearOfManufacture: yearOfManufacture,
            insuranceExpiryDate: updatedExpiryDate,
            rcImageFront: "key1",
            rcImageBack: "key2",
            vehicleImage1: "key3",
            vehicleImage2: "key4",
            vehicleImage3: "key5",
            vehicleImage4: "key6",
            vehicleImage5: "key7",
            insuranceImage: "key8",
        }

        if (type === 'editVehicle') {
            obj.agentVehicleId = rowData.agentVehicleId;
        }

        const formDataValue = new FormData();
        formDataValue.append('data', JSON.stringify(obj));
        if (vehicleRcImageFile) {
            formDataValue.append('key1', vehicleRcImageFile, vehicleRcImageFile.name);
        } else {
            formDataValue.append('key1', '');
        }
        if (vehicleRcImageFile2) {
            formDataValue.append('key2', vehicleRcImageFile2, vehicleRcImageFile2.name);
        } else {
            formDataValue.append('key2', '');
        }

        if (vehicleImageFileFile) {
            formDataValue.append('key3', vehicleImageFileFile, vehicleImageFileFile.name);
        } else {
            formDataValue.append('key3', '');
        }
        if (vehicleImageFileFile1) {
            formDataValue.append('key4', vehicleImageFileFile1, vehicleImageFileFile1.name);
        } else {
            formDataValue.append('key4', '');
        }
        if (vehicleImageFileFile2) {
            formDataValue.append('key5', vehicleImageFileFile2, vehicleImageFileFile2.name);
        } else {
            formDataValue.append('key5', '');
        }
        if (vehicleImageFileFile3) {
            formDataValue.append('key6', vehicleImageFileFile3, vehicleImageFileFile3.name);
        } else {
            formDataValue.append('key6', '');
        }
        if (vehicleImageFileFile4) {
            formDataValue.append('key7', vehicleImageFileFile4, vehicleImageFileFile4.name);
        } else {
            formDataValue.append('key7', '');
        }

        if (insImageFileFile) {
            formDataValue.append('key8', insImageFileFile, insImageFileFile.name);
        } else {
            formDataValue.append('key8', '');
        }

        if (type === 'editVehicle') {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_EDIT_VEHICLE, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            try {
                const headers = {
                    authorization: token
                };
                const res = await axios.post(API_ADD_VEHICLE, formDataValue, { headers });
                if (res.data.responseCode === 0) {
                    toast.success(res.data.successMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    acceptDialogBox()
                } else {
                    toast.error(res.data.errorMsg, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error('Internal server error.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }

    };

    useEffect(() => {
        if (data) {
            if (data?.imageName === 'vehicleImage') {
                setvehicleImageFile(data?.image);
            } else if (data?.imageName === 'vehicleImage1') {
                setvehicleImageFile1(data?.image);
            } else if (data?.imageName === 'vehicleImage2') {
                setvehicleImageFile2(data?.image);
            } else if (data?.imageName === 'vehicleImage3') {
                setvehicleImageFile3(data?.image);
            } else if (data?.imageName === 'vehicleImage4') {
                setvehicleImageFile4(data?.image);
            } else if (data?.imageName === 'lisenceFrontImage') {
                setvehicleLicenseFile(data?.image);
            } else if (data?.imageName === 'lisenceBackImage') {
                setvehicleLicenseFile2(data?.image);
            } else if (data?.imageName === 'insImage') {
                setInsImageFile(data?.image);
            }
        }
    }, [data]);

    return (
        <Modal
            size='md'
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'editVehicle' ? 'Edit Vehicle' : 'Add Vehicle'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="customModalBody">
                <form autocomplete="off">
                    <div className="row">
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputname1">Vehicle Type</label>
                            <select className="form-select customloginFieldBorder" name='vehicleTypeId' value={vehicleTypeId} onChange={handleChange}>
                                {vehicleTypeList && vehicleTypeList.length > 0 && vehicleTypeList.map((ele) => (<option key={ele.vehicleTypeId} value={ele.vehicleTypeId}>{ele.vehicleTypeName}</option>))}
                            </select>
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputname1">Registration Number</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputname1" name='registrationNumber' value={registrationNumber} onChange={handleChange} aria-describedby="nameHelp" placeholder="Enter Registration Number" />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputnumber1">Year of Manufacture</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputnumber1" maxLength={10} name='yearOfManufacture' value={yearOfManufacture} onChange={handleChange} onKeyDown={validateNum} aria-describedby="numberHelp" placeholder="Enter Year of Manufacture" />
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label htmlFor="exampleInputnumber1">Insurance Expiry Date</label>
                            <input type="date" class="form-control customloginFieldBorder" id="exampleInputdate" name='updatedExpiryDate' max={'2099-12-31'} defaultValue={updatedExpiryDate} onKeyDown={(e) => e.preventDefault()} onChange={handleChange} aria-describedby="dateHelp" placeholder="Enter Insurance ExpiryDate" />
                        </div>
                        {/* min={type === 'editVehicle' ? moment(new Date(insuranceExpiryDate)).format('YYYY-MM-DD') : ''} */}

                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Vehicle Image 1</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload($event)}></i>}
                                            {vehicleImageUrl && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload($event)}></i>}
                                            <input type="file" id="vehiclePicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onVehicleFileChanged($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleImageUrl && <div className="card-body text-center">
                                    <img src={vehicleImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Vehicle Image 2</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleImageUrl2 ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleImageUrl2 && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload1($event)}></i>}
                                            {vehicleImageUrl2 && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload1($event)}></i>}
                                            <input type="file" id="vehiclePicInput1" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onVehicleFileChanged1($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleImageUrl2 && <div className="card-body text-center">
                                    <img src={vehicleImageUrl2} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Vehicle Image 3</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleImageUrl3 ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleImageUrl3 && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload2($event)}></i>}
                                            {vehicleImageUrl3 && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload2($event)}></i>}
                                            <input type="file" id="vehiclePicInput2" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onVehicleFileChanged2($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleImageUrl3 && <div className="card-body text-center">
                                    <img src={vehicleImageUrl3} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Vehicle Image 4</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleImageUrl4 ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleImageUrl4 && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload3($event)}></i>}
                                            {vehicleImageUrl4 && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload3($event)}></i>}
                                            <input type="file" id="vehiclePicInput3" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onVehicleFileChanged3($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleImageUrl4 && <div className="card-body text-center">
                                    <img src={vehicleImageUrl4} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Vehicle Image 5</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleImageUrl5 ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleImageUrl5 && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload4($event)}></i>}
                                            {vehicleImageUrl5 && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileVehicleUpload4($event)}></i>}
                                            <input type="file" id="vehiclePicInput4" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onVehicleFileChanged4($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleImageUrl5 && <div className="card-body text-center">
                                    <img src={vehicleImageUrl5} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <hr></hr> </div>

                    <div className="row">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>RC Image Front</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleRcImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleRcImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileRCUpload($event)}></i>}
                                            {vehicleRcImageUrl && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileRCUpload($event)}></i>}
                                            <input type="file" id="rcPicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onLicenseFileChanged($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleRcImageUrl && <div className="card-body text-center">
                                    <img src={vehicleRcImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>RC Image Back</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!vehicleRcImageUrl2 ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!vehicleRcImageUrl2 && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileRCUpload2($event)}></i>}
                                            {vehicleRcImageUrl2 && <i class="fas fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileRCUpload2($event)}></i>}
                                            <input type="file" id="rcPicInput2" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onLicenseFileChanged2($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {vehicleRcImageUrl2 && <div className="card-body text-center">
                                    <img src={vehicleRcImageUrl2} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                        <hr></hr>
                    </div>

                    <div className="row">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <div className={`card ${style.customCard}`}>
                                <div className={`card-header ${style.customCardHeader} ${style.controlPadding}`}>
                                    <h6 className={`${style.headerMaintext} m-0`}>Insurance Image</h6>
                                    <OverlayTrigger
                                        key={'left'}
                                        placement='left'
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                Click to {!insImageUrl ? 'Add' : 'Edit'} Image
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            {!insImageUrl && <i class="fa fa-plus" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileInsUpload($event)}></i>}
                                            {insImageUrl && <i class="fa fa-edit" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={($event) => initialFileInsUpload($event)}></i>}
                                            <input type="file" id="insPicInput" aria-describedby="fileHelp" style={{ display: 'none' }} onChange={($event) => onInsFileChanged($event)} />
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                {insImageUrl && <div className="card-body text-center">
                                    <img src={insImageUrl} className={style.modalImage} onError={handleImageErr} alt="" width="150" height="150" />
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={hideDialogBox}>Cancel</button>
                <button className='btn customButton acceptButton' onClick={onSaveChanged}>Save</button>
            </Modal.Footer>

        </Modal>
    );
};

AddEditVehicleComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any,
    rowData: PropTypes.any,
    userDetal: PropTypes.any,
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept,
    rowData: props.rowData,
    userDetal: state.userMenu.userDetails,
});

export default connect(mapStateToProps, null)(AddEditVehicleComponent);