import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GrSecure } from 'react-icons/gr';
import "./bookingListPage.scss";

const UserID = (props) => {

   

    return (
        // <h1>Hello</h1>
        <OverlayTrigger
            key={'top'}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {props.data.actualData.isSecure === 1 ? 'Secured' : 'Not Secured'}
                </Tooltip>
            }
        >
            <div className="d-flex w-100  justify-content-center  align-items-center">
                <GrSecure className={props.data.actualData.isSecure === 1 ? 'isSecure' : 'isnotSecure'} />
                <span className="ms-1 bodyText">{props.data.actualData.bookingId}</span>
            </div>
        </OverlayTrigger>
    );
}

export default UserID;
