import React, { useEffect, useState } from 'react';
import AgTable from '../../components/Table/AgTable';
import axios from 'axios';
import SkeletonLoader from '../../components/Loader';
import { PremiumUsers, autoSubscriptionUsers } from '../../components/Table/demoData';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AUTO_SUBSCRIPTION_USER } from '../../utils/consts/api';

const AutoSubscriptionUsers = ({ token }) => {


  const [spinner, setSpinner] = useState(true); // Set initial spinner state to true
  const [autoSubscribeUsers, setAutoSubscribeUsers] = useState([]);

  const getAllAutoSubscribers = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        authorization: token,
      };

      let res = await axios.post(
        AUTO_SUBSCRIPTION_USER,
        {},
        { headers }
      );
      if (res.data.responseCode === 0) {

        setAutoSubscribeUsers(res.data.response); // Assuming res.data contains the users data
        setSpinner(false); // Hide the spinner once data is fetched
      }
      else{
        setSpinner(false);
        toast.error(res.data.errorMsg, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      }
    } catch (err) {
      
      setSpinner(false);
      toast.error('Internal server error.', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
  };

  useEffect(() => {
    getAllAutoSubscribers();
  }, []);


  useEffect(()=>{
  },[autoSubscribeUsers])

  return (
    <div style={{height:"90vh"}}>
      {spinner ? (
        <SkeletonLoader />
      ) : (
        <AgTable
          section={'autoSubscriptionUsers'}
          rowData={autoSubscribeUsers}
          colDefs={autoSubscriptionUsers}
        />
      )}

    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

export default connect(mapStateToProps, null)(AutoSubscriptionUsers);
