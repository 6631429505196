import React from 'react';
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiPhone } from "react-icons/bi";

const PostAgentProfile = (props) => {


    return (
      <div className="d-flex w-100 align-items-center">
      <div>
        <img
          src={props.data.actualData.postedAgentImage}
          onError={props.context.handleImageErr}
          alt=""
          width="38"
          height="38"
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div className="ms-2">
        {props.data.actualData.postedFullName ? <OverlayTrigger
          key={'left'}
          placement='left'
          overlay={
            <Tooltip id={`tooltip-left`}>
              {props.data.actualData.postedFullName}
            </Tooltip>
          }
        >
          <h6 className="m-0 bodyTextEllipsis" style={{ width: '120px' }}>
            {props.data.actualData.postedFullName}
          </h6>
        </OverlayTrigger> : <h6 className="m-0">-</h6>}
        <h6 className="m-0 mt-1 bodyText" style={{ fontSize: '11.2px' }}>
         <BiPhone />+91-{props.data.actualData.postedAgentNo ? props.data.actualData.postedAgentNo : '-'}
        </h6>
      </div>
    </div>
    );
}

export default PostAgentProfile;
