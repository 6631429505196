import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './auth.module.scss';
import bgImage from '../../assets/images/login_bg_image.png';
import { EMAIL_REGEX } from '../../utils/consts/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_FORGET_PASSWORD } from '../../utils/consts/api';


const ForgetPassowrdComponent = () => {
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const { email } = inputs;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (!value) {
            setIsValid(true);
            setErrorMsg('')
        }
    };

    const validateEmail = () => {
        if (email) {
            if (!EMAIL_REGEX.test(email)) {
                setIsValid(false);
                setErrorMsg('Invalid Email Id.')
            } else {
                setIsValid(true);
                setErrorMsg('')
            }
        } else {
            setIsValid(true);
            setErrorMsg('')
        }

    }

    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (email) {
            const headers = {
                'Content-Type': 'application/json',
                'authorization': ''
            };
            const obj = {
                emailId: email
            };

            axios.post(API_FORGET_PASSWORD, obj, { headers })
                .then((response) => {
                    if (response.data.responseCode === 0) {
                        toast.success(response.data.successMsg, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate('/login');
                    } else {
                        toast.error(response.data.errorMsg, {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }, (error) => {
                    toast.error('Internal server error.', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };

    return (
        <div className={styles.authcomponent}>
            <div className={`d-none d-md-block ${styles.bgleftimage}`} style={{ backgroundImage: `url(${bgImage})` }}>
            </div>
            <div className={styles.bgrightloginform}>
                <div className={styles.formBox}>
                    <h4 className='mb-5'>Forgot Password?</h4>
                    <p className={`${styles.customNote} mb-5`}>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
                    <form autocomplete="off">
                        <div class="form-group mb-3">
                            <label htmlFor="exampleInputEmail1">Email address</label>
                            <input type="text" class="form-control customloginFieldBorder" id="exampleInputEmail1" name='email' value={email} onChange={handleChange}
                                onBlur={validateEmail} aria-describedby="emailHelp" placeholder="Enter email" />
                            {submitted && !email && (
                                <div className='invalid-feedback'>
                                    Email is required
                                </div>
                            )}
                            {!isValid && <div className="text-danger">{errorMsg}</div>}
                        </div>
                        <button type="submit" class="btn customButtonActive customButtonFP" disabled={!email || !isValid} onClick={handleSubmit}>Send Reset Instructions</button>
                    </form>
                    <p className={`${styles.customNote} mt-4`}>Return to <Link style={{ color: '#FBBB00' }} to="/login">Sign in</Link></p>
                </div>
            </div>
        </div>
    );
}

export default ForgetPassowrdComponent;
