import React from 'react';
import "./bookingListPage.scss";



const BookingStatus = (props) => {

    const getStatusText = () => {
        const status =props.data.actualData.bookingStatus;
        if (!status) return "-";
        return status.charAt(0).toUpperCase() + status.slice(1);
    };


    return (
        <div className="chipBodyText" >
          {props.data.actualData.bookingStatus ?
             <>
            {props.data.actualData.bookingStatus.toLowerCase() === 'new' && <div className="statusDiv new">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'expired' && <div className="statusDiv expired">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'assigned' && <div className="statusDiv assigned">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'cancel' && <div className="statusDiv cancel">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'picked' && <div className="statusDiv picked">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'end' && <div className="statusDiv end">{getStatusText()}</div>}
            {props.data.actualData.bookingStatus.toLowerCase() === 'deleted' && <div className="statusDiv deleted">{getStatusText()}</div>}
            </>
            :
            'NA'
             }
            </div>
    );
}

export default BookingStatus;
