import UserActionTypes from './user.types';

export const userSignInStart = (data) => ({
  type: UserActionTypes.SIGN_IN_START,
  payload: data
});

export const userSignInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const userSignInFailure = (user) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: user
});
export const adminContactUpdate = (user) => ({
  type: UserActionTypes.ADMIN_CONTACT_NUMBER,
  payload: user
});