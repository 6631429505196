import React from 'react';
import styles from "../../../views/B2C Payments/payment.module.scss";





const StatusB2c = (props) => {

    
        const getStatusText = () => {
            const status = props.data.Status;
            if (!status) return "-";
            return status.charAt(0).toUpperCase() + status.slice(1);
        };
        
    return (
        <div
        className={`${props.data.Status === "success"
          ? styles.successStatus
          : props.data.Status === "processing"
            ? styles.processingStatus
            : props.data.Status === "hold"
              ? styles.holdStatus
              : props.data.Status === "failed"
                ? styles.failedStatus
                : props.data.Status === "refunded"
                  ? styles.refundedStatus
                  : props.data.Status === "cancelled"
                    ? styles.cancelledStatus
                    : ""
          }`}
      >
        <p
          style={{ marginBottom: "0px" }}
          className={`${props.data.Status === "success"
            ? styles.successStatusText
            : props.data.Status === "processing"
              ? styles.processingStatusText
              : props.data.Status === "hold"
                ? styles.holdStatusText
                : props.data.Status === "failed"
                  ? styles.failedStatusText
                  : props.data.Status === "refunded"
                    ? styles.refundedStatusText
                    : props.data.Status === "cancelled"
                      ? styles.cancelledStatusText
                      : ""
            }`}
        >
          {props.data.Status ? getStatusText() : "-"}
        </p>
      </div>
    );
}

export default StatusB2c;
