/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './suggestion.module.scss';
import axios from 'axios';
import { API_SUGGESTION_LIST } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import { toast } from 'react-toastify';
import * as moment from 'moment';

const SuggestionComponent = ({ token }) => {
    const [suggestionList, setsuggestionList] = useState([]);
    const [supportListBackUp, setsuggestionListBackUp] = useState([]);
    // const [pageLimit, setpageLimit] = useState(5);
    const pageLimit = useRef(5);
    const searchValue = useRef('');
    // const [pageNo, setpageNo] = useState(0);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(10000000);
    const [cacheNextUserList, setcacheNextUserList] = useState([]);
    const [cachePrevUserList, setcachePrevUserList] = useState([]);

    const getNextPrevSuggestionList = async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_SUGGESTION_LIST, { page: nextPageNo, size: pageLimit.current, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.suggestionList : []);
                } else {
                    setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.suggestionList : []);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const getAllSuggestionList = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_SUGGESTION_LIST, { page: pageNo.current, size: pageLimit.current, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                setsuggestionList(res.data.response.suggestionList);
                setsuggestionListBackUp(res.data.response.suggestionList);
                if (res.data.response.suggestionList.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevSuggestionList();
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const nextCacheSet = () => {
        if (cacheNextUserList.length > 0) {
            setcachePrevUserList(suggestionList)
            //   this.cachePrevUserList = this.apiAllUsers;

            setsuggestionList(cacheNextUserList);
            setsuggestionListBackUp(cacheNextUserList);

            setcacheNextUserList([]);
            getNextPrevSuggestionList('next');
        } else {
            setcacheNextUserList([]);
            getAllSuggestionList();
        }
    }

    const prevCacheSet = () => {
        if (cachePrevUserList.length > 0) {
            setcacheNextUserList(suggestionList);
            //   this.cacheNextUserList = this.apiAllUsers;
            setsuggestionList(cachePrevUserList);
            setsuggestionListBackUp(cachePrevUserList);
            setcachePrevUserList([]);
            getNextPrevSuggestionList('prev');
        } else {
            setcachePrevUserList([]);
            getAllSuggestionList();
        }
    }


    let i = 0;
    useEffect(() => {
        if (i === 0 && cacheNextUserList.length === 0) {
            getAllSuggestionList();
            i++;
        }
    }, [cacheNextUserList]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextUserList([]);
        setcachePrevUserList([]);
        i = 0;
    }


    const nextPrev = (val) => {

        const page = pageNo.current + 1;

        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }

    const entriesPerPage = (e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
    }

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };
    const [query, setQuery] = useState('');
    const searchItems = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length >= 3) {
            handleSubmit();
        } else if (e.target.value.length === 0) {
            resetCache();
        }
    }
    const handleSubmit = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_SUGGESTION_LIST, { page: 0, size: 5, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                setsuggestionList(res.data.response.suggestionList);
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    return (
        <div className="row m-0">
            <div className="col-12">
                <h5 className='customTitleName'>Suggestions</h5>
                <div className={`card ${styles.customCard}`}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">

                                <div className="w-100 customPginationDiv">
                                    <div class="col-md-8 me-auto">
                                        <div class="d-flex form-inputs">

                                            <input className={`form-control ${styles.searchBar}`} type="text"
                                                onChange={searchItems}
                                                placeholder="Search..."
                                                value={query}
                                            />
                                            <i class="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '11px', cursor: 'pointer' }} onClick={handleSubmit}></i>
                                        </div>
                                    </div>
                                    <h6 className="customText">Items per page</h6>
                                    <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0 || suggestionList.length < 5} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                    <button className="btn customButtons" disabled={pageLimit.current >= suggestionList.length && cacheNextUserList.length === 0 || suggestionList.length < 5} onClick={() => nextPrev('next')}>
                                        <i className="fas fa-angle-right"></i></button>
                                </div>

                            </div>
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-borderless custumTable">
                                        <thead className="tablehead">
                                            <tr>
                                                <th className="headText">User</th>
                                                <th className="headText">Company Name</th>
                                                <th className="headText">Mobile Number</th>
                                                <th className="headText">Date & Time</th>
                                                <th className="headText">Details</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableBody">
                                            {suggestionList && suggestionList.length > 0 && suggestionList.map((ele) => (<tr>
                                                <td>
                                                    <div className="d-flex w-100 align-items-center">
                                                        <div>
                                                            <img src={ele.agentImage} onError={handleImageErr} alt="" width="38" height="38"
                                                                style={{ borderRadius: '50%' }} />
                                                        </div>
                                                        <div className="ms-2">
                                                            <h6 className="m-0 bodyText">{ele.fullName}</h6>
                                                            {/* <h6 className="m-0 locationText">Bangalore</h6> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="bodyText">{ele.companyName}</td>
                                                <td className="bodyText">{ele.mobileNumber}</td>
                                                <td className="bodyText">{moment(new Date(ele.createTs)).format('MMMM D, Y')}</td>
                                                <td className="bodyText">{ele.suggestion}</td>
                                            </tr>))}
                                            {suggestionList && suggestionList.length === 0 &&
                                                <div className="text-center w-100">
                                                    <h4>No Data Found</h4>
                                                </div>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SuggestionComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(SuggestionComponent);