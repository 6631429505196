import React from 'react';

const UserVerify = (props) => {
    return (
        <div 
        style={props.data.actualData.verifiedFlag === "Verified" ? { color: "green" } : { color: "#FF4D4F" }}
        >
        {props.data.actualData.verifiedFlag}
        </div>
    );
}

export default UserVerify;
