import React from 'react';
import { convertTextField } from '../demoData';

const SubscriberProfile = (props) => {
    return (
        
        <div className="d-flex w-100 align-items-center">
            <div>
                <img src={props.data.actualData.image||props.data.actualData.userPhoto||''} onError={props.context.handleImageErr} alt="" width="38" height="38"
                    style={{ borderRadius: '50%' }} />
            </div>
            <div className="ms-2">
                <h6 className="m-0 bodyText">
                    {convertTextField(props.data.actualData.fullName)}
                    </h6>
                    {/* <h6 className="m-0 locationText">Bangalore</h6> */}
            </div>
        </div>
   
    );
}

export default SubscriberProfile;
