import { useState } from "react"
import Compressor from "compressorjs";

export const useImageCompression  = ()=>{
    const [data,setData] = useState({image:null,imageName:null,value:null})

    const imageCompressionfn = async (image,imageName,type)=>{

       new Compressor(image, {
            quality: 0.4,
            convertSize: "500000",
            success:  (compressedResult) => {
               setData({image:compressedResult,imageName:imageName,type:type});
              // compressedResult has the compressed file.
              // Use the compressed file to upload the images to your server.
            },
          });
    }

    return{
        imageCompressionfn,
        data
    }
}

export const useImageCompressor = () => {
  const compressImage = async (image, imageName, type) => {
    return new Promise((resolve, reject) => {
      new Compressor(image,
        {
          quality: 0.4,
          convertSize: "500000",
          success: (compressedResult) => {
            resolve(compressedResult);
          },
          error: (error) => { reject(error); },
        });
    });
  };
  return { compressImage };
}; 