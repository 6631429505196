import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './profile.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { API_NUMBER_UPDATE, API_CHANGE_PASSWORD } from '../../utils/consts/api';
import { adminContactUpdate } from '../../redux/user/user.actions';

const AdminProfileComponent = ({ token, emailid, userContact, adminContactUpdate }) => {

    const [inputsContact, setinputsContact] = useState({
        contactNumber: userContact
    });
    const { contactNumber } = inputsContact;
    const [isValidcontNumber, setIsValidcontNumber] = useState(false);
    const [errorMsgcontNumber, setErrorMsgcontNumber] = useState('');

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setinputsContact((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'contactNumber') {
            if (!value) {
                setIsValidcontNumber(true);
                setErrorMsgcontNumber('Contact number is required,');
            } else {
                if (value.length > 10 || value.length < 10) {
                    setIsValidcontNumber(true);
                    setErrorMsgcontNumber('Number must be of 10 digit.');
                } else {
                    setIsValidcontNumber(false);
                    setErrorMsgcontNumber('');
                }
            }

        }
    };

    const [inputs, setInputs] = useState({
        oldPassword: '',
        newPassword: '',
        cnfPassword: ''
    });
    const { oldPassword, newPassword, cnfPassword } = inputs;

    const [isValidcnfpassword, setIsValidcnfpassword] = useState(false);
    const [errorMsgcnfpassword, setErrorMsgcnfpassword] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'cnfPassword') {
            if (!value) {
                setIsValidcnfpassword(false);
                setErrorMsgcnfpassword('');
            } else {
                if (newPassword !== value) {
                    setIsValidcnfpassword(true);
                    setErrorMsgcnfpassword('Password does not match');
                } else {
                    setIsValidcnfpassword(false);
                    setErrorMsgcnfpassword('');
                }
            }

        }
    };

    const cnfPasswordFunc = () => {
        if (cnfPassword) {
            if (newPassword !== cnfPassword) {
                setIsValidcnfpassword(true);
                setErrorMsgcnfpassword('Password does not match');
            } else {
                setIsValidcnfpassword(false);
                setErrorMsgcnfpassword('');
            }
        } else {
            setIsValidcnfpassword(false);
            setErrorMsgcnfpassword('');
        }
    };

    const [showOldPassword, setshowOldPassword] = useState(false);

    const handleOldPassword = () => {
        setshowOldPassword(!showOldPassword);
    }

    const [showNewPassword, setshowNewPassword] = useState(false);

    const handleNewPassword = () => {
        setshowNewPassword(!showNewPassword);
    }

    const [showCnfPassword, setshowCnfPassword] = useState(false);

    const handleCnfPassword = () => {
        setshowCnfPassword(!showCnfPassword);
    }

    const [manageEdit, setmanageEdit] = useState(false);

    const handleEdit = () => {
        setIsValidcontNumber(false);
        setErrorMsgcontNumber('');
        setinputsContact((prevState) => ({
            ...prevState,
            contactNumber: userContact
        }));
        setmanageEdit(!manageEdit);
    }

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }
    let navigate = useNavigate();
    const updateNumber = async () => {
        const obj = {
            mobileNumber: contactNumber,
        }

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_NUMBER_UPDATE, obj, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                adminContactUpdate(contactNumber);
                setIsValidcontNumber(false);
                setErrorMsgcontNumber('');
                setinputsContact((prevState) => ({
                    ...prevState,
                    contactNumber: userContact
                }));
                setmanageEdit(false);
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const changePassword = async () => {
        const obj = {
            oldPassword: oldPassword,
            newPassword: newPassword

        }

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_CHANGE_PASSWORD, obj, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate('/app/dashboard');
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="row m-0">
            <div className="col-12">
                <h5 className='customTitleName'>Profile</h5>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className={`card ${styles.customCard}`}>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='w-100'>
                                            <span className={styles.actionEdit}>
                                                {!manageEdit && <OverlayTrigger
                                                    key={'left'}
                                                    placement='left'
                                                    overlay={
                                                        <Tooltip id={`tooltip-left`}>
                                                            Edit
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i class="fas fa-edit float-end" onClick={handleEdit}></i>
                                                </OverlayTrigger>}
                                            </span>
                                            <span className={styles.actionEdit}>
                                                {manageEdit && <OverlayTrigger
                                                    key={'left'}
                                                    placement='left'
                                                    overlay={
                                                        <Tooltip id={`tooltip-left`}>
                                                            View
                                                        </Tooltip>
                                                    }
                                                >

                                                    <i class="far fa-eye float-end" onClick={handleEdit} style={{ opacity: '0.5', cursor: 'pointer' }}></i>

                                                </OverlayTrigger>}
                                            </span>

                                        </div>
                                    </div>

                                    <div className='col-12'>

                                        <div class="form-group mb-3">
                                            <label htmlFor="exampleInputNumber1">Email Id</label>
                                            <h6 className={`${styles.customText}`}>{emailid}</h6>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label htmlFor="exampleInputNumber1">Contact Number</label>
                                            {!manageEdit && <h6 className={`${styles.customText}`}>{userContact}</h6>}
                                            {manageEdit && <input type="text" class="form-control customloginFieldBorder" id="exampleInputNumber1" name='contactNumber' value={contactNumber} maxLength={10} onChange={handleContactChange} onKeyDown={validateNum} aria-describedby="contactNumberHelp" placeholder="Enter Contact Number" />}
                                            {manageEdit && <div className='w-100'>
                                                {isValidcontNumber && <div className="text-danger">{errorMsgcontNumber}</div>}
                                            </div>}
                                        </div>


                                        {manageEdit && <div className='w-100 d-flex justify-content-center'>
                                            <button type="submit" disabled={!contactNumber || isValidcontNumber} class="btn customButtonActive text-center" onClick={updateNumber}>Save</button>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div className={`card ${styles.customCard}`}>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h6 className='mb-4'>Change Password</h6>

                                        <div class="form-group mb-3">
                                            {/* <label htmlFor="exampleInputPassword1">Old Password</label> */}
                                            <div class="input-group customloginFieldBorder">
                                                <input type={showOldPassword ? 'text' : 'password'} class="form-control" id="exampleInputPassword1" name='oldPassword' value={oldPassword} onChange={handleChange} placeholder="Old Password" />
                                                {!showOldPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye-slash" style={{ opacity: '0.6' }} onClick={handleOldPassword}></i>
                                                </span>}
                                                {showOldPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye" style={{ opacity: '0.6' }} onClick={handleOldPassword}></i>
                                                </span>}
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            {/* <label htmlFor="exampleInputPassword2">New Password</label> */}
                                            <div class="input-group customloginFieldBorder">
                                                <input type={showNewPassword ? 'text' : 'password'} class="form-control" id="exampleInputPassword2" name='newPassword' value={newPassword} onChange={handleChange} placeholder="New Password" />
                                                {!showNewPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye-slash" style={{ opacity: '0.6' }} onClick={handleNewPassword}></i>
                                                </span>}
                                                {showNewPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye" style={{ opacity: '0.6' }} onClick={handleNewPassword}></i>
                                                </span>}
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            {/* <label htmlFor="exampleInputPassword3">New Password</label> */}
                                            <div class="input-group customloginFieldBorder">
                                                <input type={showCnfPassword ? 'text' : 'password'} class="form-control" id="exampleInputPassword3" name='cnfPassword' value={cnfPassword} onChange={handleChange} placeholder="Confirm Password" onBlur={cnfPasswordFunc} />
                                                {!showCnfPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye-slash" style={{ opacity: '0.6' }} onClick={handleCnfPassword}></i>
                                                </span>}
                                                {showCnfPassword && <span class="input-group-text" style={{ cursor: 'pointer' }}>
                                                    <i class="far fa-eye" style={{ opacity: '0.6' }} onClick={handleCnfPassword}></i>
                                                </span>}
                                            </div>
                                            {isValidcnfpassword && <div className="text-danger">{errorMsgcnfpassword}</div>}
                                        </div>

                                        <div className='w-100 d-flex justify-content-center'>
                                            <button type="submit" class="btn customButtonActive text-center" disabled={!oldPassword || !newPassword || !cnfPassword || isValidcnfpassword} onClick={changePassword}>Change Password</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminProfileComponent.propTypes = {
    token: PropTypes.string,
    emailid: PropTypes.any,
    userContact: PropTypes.any,
    adminContactUpdate: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    emailid: state.user.userEmail,
    userContact: state.user.userContact
});

export default connect(mapStateToProps, { adminContactUpdate })(AdminProfileComponent);