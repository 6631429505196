import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Action = (props) => {



    return (
       <> <div className="bodyText text-center">
       <OverlayTrigger
           key={'top'}
           placement='top'
           overlay={
               <Tooltip id={`tooltip-top`}>
                   Delete Vehicle
               </Tooltip>
           }
       >
           <i class="fa fa-trash-alt" onClick={() => props.context.handleDeleteOpen(props.data.actualData)} style={{ opacity: '0.5', cursor: 'pointer' }}></i>
       </OverlayTrigger>
   </div></>
    );
}

export default Action;
