import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './vehicle.module.scss';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_VEHICLE_ADD } from '../../utils/consts/api';

const AddVehicleComponent = ({ token, show, onHide, onAccept }) => {
    const [inputs, setInputs] = useState({
        vehicleTypeName: ''
    });
    const { vehicleTypeName } = inputs;
    const [isValid, setIsValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value) {
            setIsValid(true);
            setErrorMsg('')
        } else {
            setIsValid(false);
            setErrorMsg('Vehicle name is required.')
        }
    };

    const validateVehicleType = () => {
        if (!vehicleTypeName) {
            setIsValid(false);
            setErrorMsg('Vehicle name is required.')
        } else {
            setIsValid(true);
            setErrorMsg('')
        }

    }

    const onSave = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_VEHICLE_ADD, { vehicleTypeName: vehicleTypeName }, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onAccept();
                setInputs((prevState) => ({
                    ...prevState,
                    vehicleTypeName: '',
                }));
                setIsValid(true);
                setErrorMsg('')
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const hideDialogBox = () => {
        onHide();
        setInputs((prevState) => ({
            ...prevState,
            vehicleTypeName: '',
        }));
        setIsValid(true);
        setErrorMsg('')
    }

    return (
     
          <Modal
            size='sm'
            show={show}
            onHide={hideDialogBox}
            centered
            backdrop="static"
            keyboard={false}
            // className={styles.customModal}
            
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add vehicle Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autocomplete="off">
                    <div class="form-group mb-3">
                        <label htmlFor="exampleInputEmail1">Vehicle type name</label>
                        <input type="text" class="form-control customloginFieldBorder" id="exampleInputVtype" name='vehicleTypeName' value={vehicleTypeName} onChange={handleChange}
                            onBlur={validateVehicleType} aria-describedby="vtypeHelp" placeholder="Enter vehicle type" />
                        {!isValid && <div className="text-danger">{errorMsg}</div>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={hideDialogBox}>Cancel</button>
                <button type="button" disabled={!vehicleTypeName} className='btn customButton acceptButton' onClick={onSave}>Save</button>
            </Modal.Footer>
        </Modal>
      
    );
};

AddVehicleComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept
});

export default connect(mapStateToProps, null)(AddVehicleComponent);