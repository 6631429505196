import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardLayout from './layouts/DashboardLayout';
import routes from './routing/index';
import UserDetails from './views/users/userDetails';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <>
      {routing}
      <ToastContainer autoClose={4000} />
    </>
  );
};

export default App;