import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_APP_GENRATEREPORT } from '../utils/consts/api';
import { toast } from 'react-toastify';

function PinDialogue({token , downloadFile }) {
    const [show, setShow] = useState(false);
    const inputRef=useRef(null);
    const [isDownloading, setIsDownloading] = useState(false); 

  const handleClose = async () => {
    if(inputRef.current.value && inputRef.current.value.length>=6 ){
      const inputValue= inputRef.current.value;
      setIsDownloading(true);
      try{
        await downloadFile(inputValue);
      }catch(err){
        toast.error(err);
      }finally{
        setIsDownloading(false);
      }
    }else if(!inputRef.current.value){
      toast.error('Field is empty')
    }else if(inputRef.current.value.length < 6 ){
      toast.error('Pin should be atleast 6 digits')
    }
    setShow(false)
  };
  const handleShow = () => setShow(true);
  
useEffect(()=>{
  if(show && inputRef.current){
    inputRef.current.focus();
  }
},[show]);
  return (
    <>
      <Button 
      style={{backgroundColor:'#fbbb00',border:'none' , boxShadow: 'none',}}
      onClick={handleShow}>
        Export Data 
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="28" 
        height="18" 
        fill="currentColor" 
        className="bi bi-cloud-arrow-down-fill" 
        viewBox="0 0 16 16"
      >
        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708"/>
      </svg>
      </Button>

      <Modal show={show} >
        <Modal.Header>
          <Modal.Title>Enter Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <InputGroup size="md">
        <Form.Control 
          aria-label="Large"
          aria-describedby="inputGroup-sizing-sm"
          ref={inputRef}
          type='password'
        />
      </InputGroup></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button
          disabled={isDownloading}
          style={{backgroundColor:'#fbbb00',border:'none'}} onClick={handleClose}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PinDialogue;