import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './support.module.scss';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_RESPONDB2CTICKET_TICKET } from '../../utils/consts/api';

const AdminSupportComponent = ({ token, show, rowData, onHide, onAccept }) => {

    const [inputs, setInputs] = useState({
        adminResponse: ''
    });
    const { adminResponse } = inputs;
    const [isValid, setIsValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value) {
            setIsValid(true);
            setErrorMsg('')
        } else {
            setIsValid(false);
            setErrorMsg('Comment is required.')
        }
    };

    const validateComment = () => {
        if (!adminResponse) {
            setIsValid(false);
            setErrorMsg('Comment is required.')
        } else {
            setIsValid(true);
            setErrorMsg('')
        }

    }

    const onSave = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_RESPONDB2CTICKET_TICKET, { ticketId: rowData.ticketId, adminResponse: adminResponse }, { headers });
            if (res.data.responseCode === 0) {
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onAccept();
                setInputs((prevState) => ({
                    ...prevState,
                    adminResponse: '',
                }));
                setIsValid(true);
                setErrorMsg('')
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const hideDialogBox = () => {
        onHide();
        setInputs((prevState) => ({
            ...prevState,
            adminResponse: '',
        }));
        setIsValid(true);
        setErrorMsg('')
    }



    return (
        <Modal
            size='md'
            show={show}
            onHide={hideDialogBox}
            centered
            backdrop="static"
            keyboard={false}
            className="customModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Respond Query
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autocomplete="off">
                    <div class="form-group mb-3">
                        <label htmlFor="exampleInputEmail1">Comments:</label>
                        <textarea class="form-control textArea customloginFieldBorder" rows="5" id="comment" name="adminResponse" value={adminResponse} onChange={handleChange}
                            onBlur={validateComment}></textarea>
                        {!isValid && <div className="text-danger">{errorMsg}</div>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn customButton cancleButton' onClick={hideDialogBox}>Cancel</button>
                <button className='btn customButton acceptButton' disabled={!adminResponse} onClick={onSave}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

AdminSupportComponent.propTypes = {
    token: PropTypes.string,
    show: PropTypes.any,
    onHide: PropTypes.any,
    onAccept: PropTypes.any,
    rowData: PropTypes.any
};

const mapStateToProps = (state, props) => ({
    token: state.user.token,
    show: props.show,
    onHide: props.onHide,
    onAccept: props.onAccept,
    rowData: props.rowData
});

export default connect(mapStateToProps, null)(AdminSupportComponent);