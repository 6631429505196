import React from 'react';
import styles from "../../../views/B2C Payments/payment.module.scss";
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

const PaymentProcessB2C = (props) => {
    return (
        <div className={`${styles.buttonDiv}`}>
                                  {props.data.actualData.paymentStatus === "processing" ? (
                                    <button
                                      className={`${styles.button}`}
                                      style={{ textAlign: "center" }}
                                      onClick={() =>
                                        props.context.handleDeleteOpen(props.data.actualData)
                                      }
                                    >
                                      Hold
                                    </button>
                                  ) : props.data.actualData.paymentStatus === "hold" ? (
                                    <button
                                      className={`${styles.paymentHoldButton}`}
                                      style={{ textAlign: "center" }}
                                      disabled
                                    >
                                     <p> Payment on Hold{" "}</p>
                                    </button>
                                  ) : props.data.actualData.paymentStatus === "paid" ? (
                                    <p className={`${styles.paidToVendor}`}>
                                      Paid to Vendor{" "}
                                    </p>
                                  ) : props.data.actualData.paymentStatus === "refunded" ? (
                                    <p className={`${styles.refunded}`}>
                                      {" "}
                                      Refunded{" "}
                                    </p>
                                  ) : props.data.actualData.paymentStatus === "success" ? (
                                    <p className={`${styles.success}`}>
                                      {" "}
                                      Completed{" "}
                                    </p>
                                  ) : props.data.actualData.paymentStatus === "cancelled" ? (
                                    <p className={`${styles.cancelled}`}>
                                      {" "}
                                      Cancelled{" "}
                                    </p>
                                  ) :
                                  props.data.actualData.paymentStatus === "failed" ?
                                      <div
                                        className={`${styles.paymentFailedDiv}`}
                                      >
                                        <p
                                          style={{ marginBottom: "0px" }}
                                          className={`${styles.paymentFailedText}`}
                                        >
                                          Payment failed
                                        </p>
                                        <span
                                          className="bodyText text-center"
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <OverlayTrigger
                                            key={"top"}
                                            placement="left"
                                            overlay={
                                              <Popover id="popover-basic">
                                                <Popover.Header as="h3">
                                                  Payment Failed Cause
                                                </Popover.Header>
                                                <Popover.Body>
                                                  {props.data.actualData.paymentFailedReason
                                                    ? props.data.actualData.paymentFailedReason
                                                    : "Reason is not specify"}
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <i
                                              class={`far fa-eye ${styles.actionBtn}`}
                                            ></i>
                                          </OverlayTrigger>
                                        </span>
                                        <span>
                                          <OverlayTrigger
                                            key={"bottom"}
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id={`tooltip-bottom`}>
                                                Click to edit
                                              </Tooltip>
                                            }
                                          >
                                            <span className={styles.actionBtn}>
                                              <i
                                                class="fas fa-edit"
                                                onClick={() =>
                                                  props.context.handleEditFunc(props.data.actualData.bookingId)
                                                }
                                              ></i>
                                            </span>
                                          </OverlayTrigger>
                                        </span>
                                      </div>
                                      : "-"
                                  }
                                </div>
    );
}

export default PaymentProcessB2C;
